.definition{
    padding:$space 0;
    background:$white;
    @media (max-width: $mob) {
        padding:$space-mobile 0;
    }
    .wrap{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    &__left{
        width:calc(50% - 30px);
        @media (max-width: $tab) {
            width:100%;
        }
        .left{
            &__definition{
                font-weight: 600;
                font-size: 24px;
                line-height: 38px;
                @media (max-width: $tab) {
                    font-size: 19px;
                    line-height: 28px;
                }
                &:before{
                    content:'\25FC'
                }
            }
            &__text{
                margin-top:50px;
                @media (max-width: $tab) {
                    margin-top:35px;
                }
                p{
                    font-size:18px;
                    @media (max-width: $tab) {
                        font-size:17px;
                    }
                }
            }
        }
    }
    &__right {
        width: calc(50% - 90px);
        @media (max-width: $tab) {
            display:none;
        }
    }
}