.intro-projet {
    .wrap {
        display: flex;
        justify-content: space-between;

        @media (max-width: $mob) {
            flex-direction: column
        }
    }

    &__text {
        width: 100%;
        max-width: 640px;
        margin-right: 50px;
        padding: $space-mobile 0;
        color: $black;

        @media (max-width: $mob) {
            width: 100%;
        }

        h1,
        h2 {
            margin-bottom: 20px;
            font-size: 38px;
            font-weight: bold;
        }

        p {
            margin: 30px 0;
            text-align: justify;
            font-weight: 300;
            font-size: 18px;
            strong, b {
                font-weight: 600;
            } 
            a {
                font-weight: 400;
                color: $primary;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

    }

    &__infos {
        width: 100%;
        max-width: 464px;
        padding: $space-mobile 0;
        color:$black;
        @media (max-width: $mob) {
            padding-top:0;
            max-width: none;
        }
        .infos {
            padding:35px 50px 35px 80px;
            background:#F2F2F2;
            border-radius: 20px ;
            @media (max-width: 1340px) {
                border-radius: 20px 0px 0px 20px;
                transform: translateX(30px);
            }
            @media (max-width: $mob) {
                transform: translateX(0);
                border-radius: 20px;
                padding: 20px 20px 20px 50px;
            }
            &__baseline{
                position:relative;
                margin-top:20px;
                font-weight:700;
                font-size:30px;
                font-style: italic;
                &::before{
                    position:absolute;
                    top:-10px;
                    left:-25px;
                    font-size:60px;
                    font-weight: 400;
                    font-style: normal;
                    content:"‘‘";
                }
                &::after{
                    margin-left:11px;
                    display: inline-block;
                    content: " ’’";
                    font-size: 60px;
                    font-weight: 400;
                    font-style: normal;
                    line-height:0;
                    transform: translateY(20px);
                }
            }
            &__website{
                margin-top:10px;
                font-size:24px;
                font-weight:bold;
            }
            &__description{
                position:relative;
                margin-top:25px;
                max-width:210px;
                font-weight:300;
                line-height:21px;
                @media (max-width: $mob) {
                    max-width: none;
                }
                &::before {
                    position: absolute;
                    top: 5px;
                    left: -25px;
                    content: "";
                    width:13px;
                    height:13px;
                    background:$primary;
            
                }
            }
        }
    }
}