.text-img{
    display:flex;
    @media (max-width: $mob) {
        flex-direction:column
    }
    &__text{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width:50%;
        padding:$space 0;
        box-sizing: border-box;
        padding-right:80px;
        @media (max-width: $tab) {
            padding-right:30px;
        }
        @media (max-width: $mob) {
            width: 100%;
            padding:$space-mobile 15px;
        }
        .text__content{
            width:100%;
            max-width:560px;
            padding-left:30px;
            @media (max-width: $mob) {
                padding: 0;
                max-width: 100%;
            }
            &--white{
                color:$white;
                a{
                    color: $white;
                }
            }
            &--black {
                color: $black;
                a {
                    color: $black;
                }
            }
            &--grey {
                color: $grey-dark;

                a {
                    color: $grey-dark;
                }
            }
            img{
                margin-bottom: 50px;
                @media (max-width: $mob) {
                    margin-bottom: 30px;
                }
            }
            h1, h2, h3, h4 {
                margin-bottom: 50px;
                font-size: 66px;
                font-weight: bold;
                font-family: $font-2;
                @media (max-width: 1440px) {
                    font-size: 64px;
                }
                @media (max-width: $tab) {
                    font-size: 60px;
                }
                @media (max-width: $mob) {
                    font-size: 40px;
                    margin-bottom: 30px;
                }
                &:before {
                    content: '# ';
                }
            }
            
            p {
                max-width: 1030px;
                margin: 30px auto;
                font-size: 18px;
                line-height:26px;
                font-weight: 300;
                text-align:justify ;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                strong, b {
                    font-weight: 600;
                }
                a {
                    text-decoration: underline;
                }
            }
            
        }
    }
    &__img{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width:50%;
        padding:$space 0;
        box-sizing: border-box;
        padding-left:80px;
        @media (max-width: $tab) {
            padding-left: 30px;
        }
        @media (max-width: $mob) {
            width: 100%;
            padding:$space-mobile 15px;
        }
        .img__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width:100%;
            max-width: 560px;
            padding-right: 30px;
            font-size:28px;
            text-align:center;
            @media (max-width: $mob) {
                padding: 0;
                max-width: 100%;
            }
            .content__text-above{
                margin-bottom:40px; 
            }
            .content__text-under {
                margin-top:40px;
            }
        }
        &--img-full{
            position:relative;
            background-size:cover;
            background-position: center;
            background-repeat: no-repeat;
            @media (max-width: $mob) {
                height:100vw;
            }
        }
    }
    &--reverse {
        flex-direction: row-reverse;
        @media (max-width: $mob) {
            flex-direction: column
        }
        .text-img {
            &__text {
                justify-content: flex-start;
                padding-right: 30px;
                padding-left: 80px;
                @media (max-width: $tab) {
                    
                    padding-left: 30px;
                }
                @media (max-width: $mob) {
                    padding-right: 0;
                    width: 100%;
                    padding: $space-mobile 15px;
                }
            
                .text__content {
                    padding-left: 0;
                    padding-right: 30px;
                    @media (max-width: $mob) {
                        padding: 0;
                    }
                }
            }
            
            &__img {
                justify-content: flex-end;
                padding-left: 0;
                padding-right: 80px;
                @media (max-width: $tab) {
                    padding-left: 0;
                    padding-right: 30px;
                }
                @media (max-width: $mob) {
                    padding: $space-mobile 15px;
                }
            
                .img__content {
                    padding-right: 0;
                    padding-left: 30px;
                    @media (max-width: $mob) {
                        padding: 0;
                    }
                }
            }
        }
    }
}