.highlight {
    position: relative;
    display: inline-block;
    font-family: $font-3;
    color: $secondary;
    transform: rotate(-3.65deg);

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -35%;
        left: calc(50% - 88px);
        width: 177px;
        height: 38px;
        background: url(../images/trait.svg) no-repeat;
        background-size: contain;
        transform: rotate(3.65deg);
    }
}