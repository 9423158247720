.intro-charte {
    .wrap {
        display: flex;
        justify-content: space-between;

        @media (max-width: $mob) {
            flex-direction: column
        }
    }

    &__text {
        width: 100%;
        max-width: 640px;
        margin-right: 50px;
        padding: $space-mobile 0;
        color: $black;

        @media (max-width: $mob) {
            width: 100%;
        }

        h1,
        h2 {
            margin-bottom: 20px;
            font-size: 38px;
            font-weight: bold;
        }

        p {
            margin: 30px 0;
            text-align: justify;
            font-weight: 300;
            font-size: 18px;
            strong, b {
                font-weight: 600;
            }
           
        }

    }

    &__infos {
        width: 100%;
        max-width: 464px;
        text-align:center;
        padding: $space-mobile 0;
        
        @media (max-width: $mob) {
            padding-top:0;
            max-width: none;
        }
        .infos {
            &__website{
                margin-top:20px;
                font-size:20px;
                font-weight:bold;
            }
           
        }
    }
}