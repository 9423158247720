.addition{
    padding: $space 0;
    &.no-padding-top{
        padding-top: 0;
    }
    .wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @media (max-width: $mob) {
          flex-direction: column;
        }
    }
    &__item{
        display:flex;
        align-items: center;
        span{
            margin-left:20px;
            font-size:24px;
            font-weight:600;
        }
    }
    &__ope{
        margin:0 30px;
        font-size: 72px;
        font-weight: 300;
        @media (max-width: $mob) {
            margin: 0;
        }
    }
}