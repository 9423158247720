.text-full-center{
    padding:$space 0;
    text-align:center;
    @media (max-width: $mob) {
        text-align:left;
    }
    @media (max-width: $mob) {
        padding: $space-mobile 0;
    }
    h1, h2, h3, h4{
        margin-bottom:50px;
        font-size:66px;
        font-weight:bold;
        @media (max-width: 1440px) {
            font-size: 64px;
        }
    
        @media (max-width: $tab) {
            font-size: 60px;
        }
    
        @media (max-width: $mob) {
            font-size: 40px;
            margin-bottom: 30px;
        }
        &:before{
            content: '# ';
        }
    }
    p{
        max-width:1030px;
        margin: 30px auto;
        font-size:18px;
        font-weight:300;
        &:last-child{
            margin-bottom:0;
        }
        strong, b {
            font-weight: 600;
        }
        a{
            text-decoration: underline;
            color: inherit;
            &:hover{
                text-decoration: none;
            }
        }
    }
}