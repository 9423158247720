.block-video{
    padding-top: $space;
    border-bottom:95px solid #ffffff;
    @media (max-width: $tab) {
        border-width: 75px;
    }
    @media (max-width: $mob) {
        padding-top: $space-mobile;
    }
   
    &__text{
        display:flex;
        justify-content: flex-end;
        @media (max-width: $tab) {
            display:block;
        }
        & > div{
            width:420px;
            @media (max-width: $mob) {
                width: 100%;
            }
            h3 {
                margin-bottom: 30px;
                font-size: 68px;
                font-weight: 700;
                font-family: Cera pro, sans-serif;
                &:before {
                    content: "# ";
                }
                @media (max-width: $tab) {
                    font-size:60px;
                }
                @media (max-width: $mob){
                    font-size: 40px;
                }
            }
            p{
                margin: 30px auto;
                font-size: 18px;
                line-height: 26px;
                font-weight: 300;
                text-align: justify;
            }
        }
    }
    &__video{
        position:relative;
        max-width:1070px;
        margin:auto;
        margin-bottom:-95px;
        @media (max-width: $tab) {
            margin-bottom: -75px;
        }
        .video__bullets{
            position:absolute;
            top:-90px;
            left:-125px;
            width:633px;
            height:370px;
            background:url(../images/bg-video.png) no-repeat;
            background-size:cover;
            @media (max-width: $tab) {
                display: none;
            }

        }
        video{
            position: relative;
            display:block;
            width:100%;
            height:auto;
        }
    }
}