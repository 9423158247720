.article{
    padding:$space 0;
    @media (max-width: $mob) {
        padding: $space-mobile 0;
    }
    .wrap{
        max-width:1060px;
    }
    img{
        max-width:100%;
    }
    h2{
        margin:50px 0 25px 0;
        font-weight:bold;
        font-size:40px;
        &:first-of-type{
            margin-top:0;
        }
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
    h3{
        a {
            text-decoration: underline;
    
            &:hover {
                text-decoration: none;
            }
        }
    }
    p{
        margin:25px 0;
        font-size:18px;
        a{
            text-decoration:underline;
            &:hover{
                text-decoration:none;
            }
        }
    }
    ul li{
        margin:18px 0;
        font-size:18px;
        a {
            text-decoration: underline;
            color:$primary;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__share{
        margin-top:60px;
        font-size:16px;
        text-align:right;
    }
}

.the_champ_sharing_title{
    margin-top:50px;
}