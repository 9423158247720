.title-charte{
    padding:$space 0;
    background-color:$primary;
    color:$white;
    @media (max-width: $mob) {
        padding: $space-mobile 0;
    }
    .wrap{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size:60px;
        font-weight:900;
        font-family: $font-2;
        text-transform:uppercase;
        @media (max-width: $mob) {
            flex-direction: column;
        }
        img{
            margin-left:30px;
            @media (max-width: $mob) {
                margin-left: 0px;
                margin-top:20px;
            }
        }
    }
}