.title-projet{
    position: relative;
    height:742px;
    .wrap{
        position: relative;
    }
    &__img{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100% !important;
        max-width: none;
        object-fit: cover;
    }
    &__return {
        position: absolute;
        top: 20px;
        left: 30px;
        display: flex;
        align-items: center;
        font-size: 17px;
        color: $black;
        text-decoration: none;
        transition: color 0.25s;
        z-index:1;
        
        @media (max-width: $mob) {
            left: 15px;
        }
        i {
            margin-right: 10px;
            transform: rotate(180deg);
            font-size: 32px;
        }
        &--white {
            color: $white;
            &:hover {
                color: $primary;
            }
        }
        &:hover {
            color: $primary;
        }
    }
    &__logo{
        position: absolute;
        top: 65px;
        left: 30px;
        @media (max-width: $mob) {
            left: 15px;
        }
    }
    &__text{
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height:742px;
        padding:0 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $white;
        text-align:center;
        @media (max-width: $mob) {
            padding: 0 15px;
        }
        .text{
            &__title{
                font-family:$font-2;
                font-size:85px;
                font-weight:900;
                text-shadow: 0px 5.48718px 2.74359px rgba(0, 0, 0, 0.41);
                text-transform:uppercase;
                @media (max-width: $mob) {
                    font-size: 60px;
                }
            }
            &__accroche{
                font-family: $font-1;
                font-size: 50px;
                font-weight: 300;
                text-shadow: 0px 2.48718px 1.34359px rgba(0, 0, 0, 0.41);
                @media (max-width: $mob) {
                    font-size: 35px;
                }
            }
        }
    }
    
}