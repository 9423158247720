.expertises{
    padding:$space 0;
    @media (max-width: $mob) {
        padding:$space-mobile 0;
    }
    &__content{
        position:relative;
        width:1000px;
        height:936px;
        margin:auto;
        @media (max-width: $tab) {
            width:100%;
            height:100vw;
        }
        .content__item{
            position: absolute;
            text-decoration:none;
            & > div {
                position: relative;
                & > div {
                    transition: transform .25s;
                }
                h2 {
                    position:absolute;
                    display:inline-block;
                    color:$white;
                    font-family: $font-2;
                    font-size:55px;
                    font-weight:900;
                    text-transform:uppercase;
                    text-align:center;
                    transition: all .25s;
                    @media (max-width: $tab) {
                        font-size:5.33vw;
                    }
                }
            } 
            &--pub{
                top:80px;
                left:0;
                @media (max-width: $tab) {
                    top:7.76vw;
                }
                & > div{
                    width:459px;
                    height:424px;
                    @media (max-width: $tab) {
                        width:42.56vw;
                        height:41.16vw;
                    }
                    & > div{
                        width:459px;
                        height:424px;
                        background:url(../images/expertise-pub@2x.png) no-repeat center;
                        background-size: contain;
                        @media (max-width: $tab) {
                            width:42.56vw;
                            height:41.16vw;
                        }
                    }
                    h2 {
                        top: 124px;
                        @media (max-width: $tab) {
                            top: 12.03vw;
                        }
                    }
                }
                
            }
            &--branding {
                top:0;
                right:0;
                &>div {
                    width: 498px;
                    height: 442px;
                    @media (max-width: $tab) {
                        width: 42.34vw;
                        height: 42.91vw;
                    }
                    &>div {
                        width: 498px;
                        height: 442px;
                        background: url(../images/expertise-branding@2x.png) no-repeat center;
                        background-size: contain;
                        @media (max-width: $tab) {
                            width: 42.34vw;
                            height: 42.91vw;
                        }
                    }
                    
                    h2 {
                        position: absolute;
                        top: 128px;
                        @media (max-width: $tab) {
                            top: 12.42vw;
                        }
                    }
                }
            }
            &--digitale {
                top:492px;
                left:28px;
                @media (max-width: $tab) {
                    top:47.76vw;
                    left:2.71vw;
                }
                &>div {
                    width: 452px;
                    height: 446px;
                    @media (max-width: $tab) {
                        width: 43.88vw;
                        height: 43.30vw;
                    }
                    &>div {
                        width: 452px;
                        height: 446px;
                        background: url(../images/expertise-digitale@2x.png) no-repeat center;
                        background-size: contain;
                        @media (max-width: $tab) {
                            width: 43.88vw;
                            height: 43.30vw;
                        }
                    }
                    
                    h2 {
                        top: 134px;
                        @media (max-width: $tab) {
                            top: 13vw;
                        }
                    }
                }
                
            }
            &--marketing {
                top:445px;
                right:25px;
                @media (max-width: $tab) {
                    top: 43.2vw;
                    right: 2.42vw;
                }
                &>div {
                    width: 455px;
                    height: 450px;
                    @media (max-width: $tab) {
                        width: 40.17vw;
                        height: 43.68vw;
                    }
                    &>div {
                        width: 455px;
                        height: 450px;
                        background: url(../images/expertise-marketing@2x.png) no-repeat center;
                        background-size: contain;
                        @media (max-width: $tab) {
                            width: 40.17vw;
                            height: 43.68vw;
                        }
                    }
                    h2 {
                        top: 116px;
                        @media (max-width: $tab) {
                            top: 11.26vw;
                        }
                    }
                }
                
            }
            &:hover{
                & > div {
                    & > div {
                        transform: rotate(8deg);
                    }
                    h2 {
                        color:$secondary;
                        transform:translateY(15px);
                        @media (max-width: $tab) {
                            transform:translateY(1.4vw);
                        }
                    }
                }
            }
        }
    }
}