.home-intro{
    position:relative;
    height:calc(100vh - 110px);
    background:$primary;
    @media (max-width: $mob) {
        display: flex;
        align-items: center;
        height: calc(100vh - 60px);
    }
    &__content{
        left:0;
        position:absolute;
        width:100%;
        height:100%;
        text-align:center;
        @media (max-width: $mob) {
            position:relative;
            //padding-top:35px;
        }
        
        .content{
            &__img{
                img{
                    height: calc(100% - 70px) !important;
                    width: auto !important;
                
                    @media (max-width: $mob) {
                        height: auto !important;
                        width: 100% !important;
                    }
                }
                
            }
            &__text{
                position:absolute;
                top:20vh;
                left:0;
                width:100%;
                text-align:center;
                @media (max-width: $mob) {
                    top:15vw;
                }
                .text{
                    &__title{
                        font-family:$font-2;
                        font-size:62px;
                        line-height: 96px;
                        font-weight:900;
                        color:$white;
                        text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.68);
                        @media (max-width: $mob) {
                            font-size: 35px;
                            line-height: 56px;
                        }
                        .highlight {
                            font-size: 136px;
                            font-weight: 400;
                            @media (max-width: $mob) {
                                font-size: 86px;
                                line-height: 56px;
                            }
                            &:after{
                                @media (max-width: $mob) {
                                    bottom: -65%;
                                    left: calc(50% - 75px);
                                    width: 140px;
                                }
                            }
                        }
                    }
                    &__button{
                        margin-top:20px;
                        @media (max-width: $mob) {
                            display:none;
                        }
                        .button{
                            width:350px;
                        }
                    }
                }
            }
            &__title{
                @media (max-width: $mob) {
                    padding-bottom: 25px;
                    margin-top:50px;
                }
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $font-2;
                    font-weight: bold;
                    font-size: 17px;
                    color: $white;
                    color: $white;
                    text-decoration: none;
                    @media (max-width: $mob) {
                        flex-direction: column;
                    }
                }
                
                i{
                    font-size:34px;
                    transform: rotate(90deg);
                }
            }
        }
    }
    &__deco-left{
        position:absolute;
        top:150px;
        left:0;
        width:238px;
        height:316px;
        background:url(../images/home-intro-left@2x.png);
        background-size:contain;
        @media (max-width: $tab) {
            display:none;
        }
    }
    &__deco-right {
        position:absolute;
        top:80px;
        right:0;
        width:390px;
        height:402px;
        background:url(../images/home-intro-right@2x.png);
        background-size:contain;
        @media (max-width: $tab) {
            display: none;
        }
    }
}