.intro-agence{
    .wrap{
        display:flex;
        justify-content: space-between;
        @media (max-width: $mob) {
            flex-direction: column
        }
    }
    &__text{
        width:100%;
        max-width: 690px;
        margin-right:50px;
        padding:$space-mobile 0;
        color:$black;
        @media (max-width: $mob) {
            width:100%;
        }
        h1, h2{
            margin-bottom:40px;
            font-size:38px;
            font-weight:bold;
        }
        p{
            margin:30px 0;
            text-align: justify;
            font-weight:300;
            font-size:18px;
            strong, b {
                font-weight: 600;
            }
            a {
                font-weight:400;
                color: $primary;
                text-decoration: underline;
            
                &:hover {
                    text-decoration: none;
                }
            }
        }
        
    }
    &__infos{
        width:100%;
        max-width:464px;
        padding: $space-mobile 0;
        .infos{
            &__top{
                padding:30px 20px;
                border-radius: 20px 20px 0 0 ;
                background:$primary;
                color:$white;
                text-align: center;
                .top{
                    &__text{
                        font-size:20px;
                        font-weight:bold;
                    }
                    &__arrow{ 
                        i{
                            font-size: 64px;
                            transform: rotate(90deg);
                        }
                    }
                    &__agence{
                        display: inline-block;
                        margin-bottom:20px;
                        text-align: left;
                        .agence{
                            &__city {
                                margin-bottom:20px;
                                text-transform: uppercase;
                                font-size: 25px;
                                font-weight:600;
                            }
                            &__text{
                                display: flex;
                                margin-top: 5px;
                                font-size: 18px;
                                line-height: 1.5;
                                color: $white;
                                    text-decoration: none;
                                i{
                                    margin-right: 5px;
                                    font-size: 23px;
                                }
                            }
                        }
                    }
                    
                }
            }
            &__map{
                iframe{
                    width:100%;
                    height:440px;
                    border-radius:0 0 20px 20px;
                }
            }
        }
    }
}