.categories-projet{
    padding: 60px 0;
    .wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @media (max-width: $mob) {
          flex-direction: column;
        }
    }
    &__item{
        display:flex;
        align-items: center;
        margin:10px 40px;
        color:$white;
        text-decoration:none;
        @media (max-width: $mob) {
            margin: 10px 0;
        }
        span{
            margin-left:20px;
            font-size:24px;
            font-weight:600;
        }
    }
}