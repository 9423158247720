$white: #ffffff;
$black: #000000;

$grey-dark : #464646; 
$grey : #7C7C7C;
$grey-light : #FBF9F6;

$primary : #E5007D;
$primary-dark : #cc0070;
$secondary : #FCED4E;

$green : #42be35;
$green-light : #f0fff0;

$red :#ff0000; 
$red-light : #fff0f0;


