.link-arrow{
    a{
        display: inline-flex;
        align-items: center;
        color: $primary;
        text-decoration: none !important;
        font-size:400!important;
        span{
            text-decoration: underline;
        }
        i {
            margin-left: 5px;
            font-size: 30px;
            transition: all 0.25s;
            text-decoration: none;
        }
        &:hover {
            i {
                transform: translateX(10px);
            }
        }
    }
    &.white{
        a{
            color:$white !important;
        }
    }
    &.grey {
        a {
            color: $grey !important;
        }
    }
    &.black {
        a {
            color: $black !important;
        }
    }
    &.pink {
        a {
            color: $primary !important;
        }
    }
    &.yellow {
        a {
            color: $secondary !important;
        }
    }
}