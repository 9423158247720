.custom-message{
    display:none;
    padding:10px 0;
    .wrap{
        position: relative;
        text-align:center;
        @media (max-width: $tab) {
            font-size: 13px;
        }
    }
    &__close{
        position:absolute;
        top:0;
        right:0;
        cursor:pointer;
    }
}