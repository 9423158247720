.contact{
    padding:$space 0;
    background:$primary;
    text-align:center;
    color:$white;
    @media (max-width: $mob) {
        padding: $space-mobile 0;
    }
    p{
        margin-top:40px;
        font-size:28px;
        font-weight:bold;
        @media (max-width: $tab) {
            font-size: 23px;
        }
        @media (max-width: $mob) {
            font-size:18px;
        }
    }
    &__arrow{
        margin-bottom:20px;
        transform: rotate(90deg);
        i{
            font-size:64px;
            @media (max-width: $mob) {
                font-size: 54px;
            }
        }
    }
}