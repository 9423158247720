$gutter-width: 15px;

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -$gutter-width;
  margin-right: -$gutter-width;
  &--vertical-center {
    align-items: center;
  }
  &.mob-no-padding {
    @media (max-width: $mob) {
      margin-left: 0;
      margin-right: 0;
      & > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-1-5,
.col-2-5,
.col-3-5,
.col-4-5 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $gutter-width;
  padding-left: $gutter-width;
}
.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-1-5 {
  flex-basis: 20%;
  max-width: 20%;
}
.col-2-5 {
  flex-basis: 40%;
  max-width: 40%;
}
.col-3-5 {
  flex-basis: 60%;
  max-width: 60%;
}
.col-4-5 {
  flex-basis: 80%;
  max-width: 80%;
}

@media (max-width: $desksm) {
  .col-desksm-1,
  .col-desksm-2,
  .col-desksm-3,
  .col-desksm-4,
  .col-desksm-5,
  .col-desksm-6,
  .col-desksm-7,
  .col-desksm-8,
  .col-desksm-9,
  .col-desksm-10,
  .col-desksm-11,
  .col-desksm-12,
  .col-desksm-1-5,
  .col-desksm-2-5,
  .col-desksm-3-5,
  .col-desksm-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $gutter-width;
    padding-left: $gutter-width;
  }

  .col-desksm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-desksm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-desksm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-desksm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-desksm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-desksm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-desksm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-desksm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-desksm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-desksm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-desksm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-desksm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-desksm-1-5 {
    flex-basis: 20%;
    max-width: 20%;
  }
  .col-desksm-2-5 {
    flex-basis: 40%;
    max-width: 40%;
  }
  .col-desksm-3-5 {
    flex-basis: 60%;
    max-width: 60%;
  }
  .col-desksm-4-5 {
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media (max-width: $tab) {
  .col-tab-1,
  .col-tab-2,
  .col-tab-3,
  .col-tab-4,
  .col-tab-5,
  .col-tab-6,
  .col-tab-7,
  .col-tab-8,
  .col-tab-9,
  .col-tab-10,
  .col-tab-11,
  .col-tab-12,
  .col-tab-1-5,
  .col-tab-2-5,
  .col-tab-3-5,
  .col-tab-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $gutter-width;
    padding-left: $gutter-width;
  }

  .col-tab-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-tab-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-tab-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-tab-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-tab-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-tab-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-tab-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-tab-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-tab-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-tab-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-tab-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-tab-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-tab-1-5 {
    flex-basis: 20%;
    max-width: 20%;
  }
  .col-tab-2-5 {
    flex-basis: 40%;
    max-width: 40%;
  }
  .col-tab-3-5 {
    flex-basis: 60%;
    max-width: 60%;
  }
  .col-tab-4-5 {
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media (max-width: $mob) {
  .col-mob-1,
  .col-mob-2,
  .col-mob-3,
  .col-mob-4,
  .col-mob-5,
  .col-mob-6,
  .col-mob-7,
  .col-mob-8,
  .col-mob-9,
  .col-mob-10,
  .col-mob-11,
  .col-mob-12,
  .col-mob-1-5,
  .col-mob-2-5,
  .col-mob-3-5,
  .col-mob-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $gutter-width;
    padding-left: $gutter-width;
  }

  .col-mob-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-mob-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-mob-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-mob-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-mob-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-mob-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-mob-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-mob-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-mob-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-mob-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-mob-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-mob-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-mob-1-5 {
    flex-basis: 20%;
    max-width: 20%;
  }
  .col-mob-2-5 {
    flex-basis: 40%;
    max-width: 40%;
  }
  .col-mob-3-5 {
    flex-basis: 60%;
    max-width: 60%;
  }
  .col-mob-4-5 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
