.clients{
    padding:$space 0;
    @media (max-width: $mob) {
        padding:$space-mobile 0;
    }
    &__list{
        display:flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .list__item{
            width:25%;
            text-align:center;
            margin:20px 0;
            @media (max-width: $tab) {
                width:30%
            }
            @media (max-width: $mob) {
                width: 45%
            }
        }
    }
}