.content-404{
    max-width:800px;
    margin:auto;
    margin-bottom:120px;
    h1{
        margin-top:40px;
        font-family: $font-2;
        color:$primary;
        font-size:50px;
        font-weight:bold;
        @media (max-width: $mob) {
            font-size: 35px;
        }
    }
    p{
        margin: 30px 0;
        font-family: $font-2;
        font-size: 25px;
        font-weight: 300;
        @media (max-width: $mob) {
            font-size: 20px;
        }
    }

}