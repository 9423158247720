.colors-charte{
    display:flex;
    @media (max-width: $mob) {
        flex-direction:column
    }
    &__text{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width:50%;
        padding:$space 0;
        box-sizing: border-box;
        padding-right:80px;
        @media (max-width: $tab) {
            padding-right:30px;
        }
        @media (max-width: $mob) {
            width: 100%;
            padding:$space-mobile 15px;
        }
        .text__content{
            width:100%;
            max-width:560px;
            padding-left:30px;
            color:$black;
            @media (max-width: $mob) {
                padding: 0;
                max-width: 100%;
            }
            &--white{
                color:$white;
                a{
                    color: $white;
                }
            }
            &--black {
                color: $black;
                a {
                    color: $black;
                }
            }
            &--grey {
                color: $grey-dark;

                a {
                    color: $grey-dark;
                }
            }
            img{
                margin-bottom: 50px;
                @media (max-width: $mob) {
                    margin-bottom: 30px;
                }
            }
            h1, h2 {
                margin-bottom: 50px;
                font-size: 68px;
                font-weight: bold;
                font-family: $font-2;
                @media (max-width: $tab) {
                    font-size: 60px;
                }
                @media (max-width: $mob) {
                    font-size: 40px;
                    margin-bottom: 30px;
                }
            }
            
            p {
                max-width: 1030px;
                margin: 30px auto;
                font-size: 18px;
                line-height:26px;
                font-weight: 300;
                text-align:justify ;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    text-decoration: underline;
                }
            }
            
        }
    }
    &__colors{
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        box-sizing: border-box;
        position:relative;
        @media (max-width: $mob) {
            width: 100%;
            height:100vw;
        }
        & > div{
            position:absolute;
            display:flex;
            align-items: flex-end;
            box-sizing: border-box;
            padding:20px;
            font-size:15px;
            font-weight:bold;
            font-family:$font-2;
            @media (max-width: $mob) {
                padding: 15px;
                font-size: 13px;
            }
        }
        &--2{
            & > div{
                width:50%;
                height:100%;
            }
            &>div:nth-child(1) {
                top:0;
                left:0;
            }

            &>div:nth-child(2) {
                top: 0;
                left: 50%;
            }
        }
        &--3 {
            &>div {
                width: calc(100% / 3);
                height: 100%;
            }
            &>div:nth-child(1) {
                top: 0;
                left: 0;
            }

            &>div:nth-child(2) {
                top: 0;
                left: calc(100% / 3);
            }
            &>div:nth-child(3) {
                top: 0;
                left: calc((100% / 3)*2);
            }
        }
        &--4 {
            &>div {
                width: 50%;
                height: 50%;
            }
            &>div:nth-child(1) {
                top: 0;
                left: 0;
            }

            &>div:nth-child(2) {
                top: 0;
                left: 50%;
            }

            &>div:nth-child(3) {
                top: 50%;
                left: 0%;
            }
            &>div:nth-child(4) {
                top: 50%;
                left: 50%;
            }
        }
        &--5 {
            &>div {
                height: 50%;
            }
            & > div:nth-child(1) {
                width: 50%;
                top: 0;
                left: 0;
            }
            &>div:nth-child(2) {
                width: 50%;
                top: 0;
                left: 50%;
            }
            &>div:nth-child(3) {
                width: calc(100% / 3);
                top: 50%;
                left: 0;
            }
            &>div:nth-child(4) {
                width: calc(100% / 3);
                top: 50%;
                left: calc(100% / 3);
            }
            &>div:nth-child(5) {
                width: calc(100% / 3);
                top: 50%;
                left: calc((100% / 3)*2);
            }         
        }
        &--6 {
            &>div {
                width: calc(100% / 3);
                height: 50%;
            }

            &>div:nth-child(1) {
                top: 0;
                left: 0;
            }

            &>div:nth-child(2) {
                top: 0;
                left: calc(100% / 3);
            }

            &>div:nth-child(3) {
                top: 0;
                left: calc((100% / 3)*2);
            }

            &>div:nth-child(4) {
                top: 50%;
                left: 0;
            }

            &>div:nth-child(5) {
                top: 50%;
                left: calc(100% / 3);
            }

            &>div:nth-child(6) {
                top: 50%;
                left: calc((100% / 3)*2);
            }
        }
    }
    &--reverse {
        flex-direction: row-reverse;
        @media (max-width: $mob) {
            flex-direction: column
        }
        .colors-charte {
            &__text {
                justify-content: flex-start;
                padding-right: 30px;
                padding-left: 80px;
                @media (max-width: $tab) {
                    
                    padding-left: 30px;
                }
                @media (max-width: $mob) {
                    padding-right: 0;
                    width: 100%;
                    padding: $space-mobile 15px;
                }
            
                .text__content {
                    padding-left: 0;
                    padding-right: 30px;
                    @media (max-width: $mob) {
                        padding: 0;
                    }
                }
            }
        }
    }
}