.discover-links{
    width: fit-content;
    margin-top: 50px;
    background: $grey-light;
    border-radius: 30px;
    padding: 20px 25px;
    @media (max-width: $mob) {
        padding: 15px;
    }
    &__title {
        color: $grey-dark;
        font-size: 18px;
        font-weight: bold;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .button {
            margin: 10px 10px 0 10px;
            @media (max-width: $mob) {
                margin: 10px 5px 0 5px;
            }
        }
    }
}