.button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 45px;
    padding: 10px 20px;
    box-sizing: border-box;
    background: $primary;
    border: 1px solid $primary;
    border-radius:45px;
    color: $white;
    font-size:21px;
    font-weight:bold;
    text-decoration:none;
    transition: all 0.25s;
    cursor:pointer;
    &__text {
        transition: opacity 0.25s;
    }
    &__text-hover {
        position: absolute;
        opacity: 0;
        transition: opacity 0.25s;
    }
    &:hover, &.active{
        border-color:$primary;
        background:$secondary;
        color:$primary;
        .button{
            &__text {
                opacity: 0;
            }
            &__text-hover {
                opacity: 1;
            }
        }
    }
    &--xsm {
        padding: 6px 10px;
        min-height: 36px;
        font-size: 14px;
    }
    &--sm{
        padding: 8px 20px;
        min-height: 40px;
        font-size:16px;
    }
    &--big {
        padding: 8px 25px;
        min-height: 50px;
        font-size: 26px;
    }
    &--white {
        background: $white;
        color: $primary;
        border-color: $white;
        &:hover,
        &.active {
            border-color: $secondary;
        }
    }
    &--white-bordered {
        background: $white;
        color: $primary;
        border-color: $primary;
    }
    &--outline{
        background:transparent;
        color:$primary;
    }
    &--outline-white {
        background: transparent;
        color: $white;
        border-color:$white
    }

    
}