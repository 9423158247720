.realisez{
    p{
        font-size:25px;
        margin:0 0 25px 0;
        @media (max-width: $mob) {
            font-size: 21px;
        }
    }
    &__arrow {
        margin-bottom: 20px;
        transform: rotate(90deg);
        i{
            font-size: 64px;
            color:$primary;
        }
    }
}