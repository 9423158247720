html { 
  scroll-behavior: smooth; 
}
body {
	margin: 0;
	font-family: $font-1;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3;
	color:$grey-dark;
	
}

h1, h2, h3, h4, h5, h6{
	margin:0;
	font-weight:normal;
	line-height:1.1;;
}

p {
	margin: 15px 0;
	a {
		color: $primary;
		text-decoration: none;
		&:hover {
			//text-decoration: underline;
		}
	}
	img{
		max-width:100%;
		height:auto !important;
	}
}

ul{
	padding-left:22px;
	li{
		margin-bottom:10px;
		&:last-child{
			margin:0;
		}
		ul li{
			&:first-child{
				margin-top:10px;
			}
		}
	}
}

img {
	max-width: 100%;
	height: auto !important;
}