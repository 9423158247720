.avis-flottants{
    position:fixed;
    right:0;
    top:calc(50% - 85px);
    width:41px;
    height:171px;
    background:url(../images/avis-clients@2x.jpg) no-repeat;
    background-size:cover;
    border:1px solid lighten($grey, 30%);
    @media (max-width: $tab) {
       display:none;
    }
}

.avis-verifies{
    display:flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 540px) {
        flex-direction: column;
        align-items: center;
    }
    &__intro{
        position:relative;
        width:170px;
        padding:15px 0;
        background:#ffffff;
        text-align:center;
        //background-size: cover;
        text-decoration:none;
        .intro{
           
            &__note{
                width:100%;
                text-align:center;
                font-weight:bold;
                color:#808080;
                font-size:19px;
            }
            &__text{
                width: 100%;
                text-align: center;
                color: #808080;
                font-size:19px;
            }
        }
    }
    &__slider{
        position:relative;
        width: calc(100% - 170px);
        padding:0 40px;
        box-sizing: border-box;
        @media (max-width: 540px) {
            width:100%;
            padding: 0 20px;
            margin-top:20px;
        }
        .slider {
            &__prev {
                position: absolute;
                top: calc(50% - 17px);
                left: 10px;
                width: 23px;
                height: 35px;
                background: url(../images/avis-prev.png) no-repeat;
                background-size: cover;
                cursor: pointer;
                @media (max-width: 540px) {
                    left: -6px;
                }
            }

            &__next {
                position: absolute;
                top: calc(50% - 17px);
                right: 10px;
                width: 23px;
                height: 35px;
                background: url(../images/avis-next.png) no-repeat;
                background-size: cover;
                cursor: pointer;
                @media (max-width: 540px) {
                    right: -6px;
                }
            }
        }
        .slider__item{
            position:relative;
            height: 145px;
            margin:0 3px;
            background:$white;
            overflow: hidden;
            padding: 5px 15px;
            box-shadow: 0 4px 4px -5px #c8c8c8;
            border: 1px solid #ccc;
            box-sizing: border-box;
            border-radius: 5px;
            color: #818181;
            .item{
                &__top{
                    display: flex;
                    justify-content: space-between;
                    padding-top:5px;
                    font-size: 11px;
                    div{
                        display:flex;
                    }
                }
                &__text{
                    margin-top:10px;
                    font-size: 13px;
                }
                &__author{
                    position:absolute;
                    bottom:10px;
                    font-size: 11px;
                }
                &__link{
                    position:absolute;
                    top:0;
                    left:0;
                    width: 100%;
                    height:100%;
                }
            }
        }
    }
}