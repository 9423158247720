.contact-mobile{
    position:fixed;
    bottom:0;
    left:0;
    display:none;
    align-items: center;
    justify-content: center;
    width:100%;
    height:80px;
    background:$primary;
    text-align:center;
    box-shadow: 0 4px 18px rgba(0, 0, 0, .2);
    @media (max-width: $mob) {
        display:flex;
    }
}