.homepage{
    .header {
        background: $primary;
        
        .logo {
            position: relative;
        
            img {
                display: block;
            }
        
            .logo__img--white {
                position: absolute;
                top: 0;
                display: block;
            }
        }
    
        .header__links {
            .links__contact {
                display:none;
            }
            a {
                color: $white;
                &:hover {
                    color: $secondary;
                }
            }
    
            .btn-menu {
                color: $white;
    
                &:hover {
                    color: $secondary;
                }
            }
        }
        &.scroll {
            background: $white;
            .logo {            
                .logo__img--white {
                    display: none;
                }
            }
            .header__links {
                a {
                    color: $grey-dark;
                }
            
                .btn-menu {
                    color: $grey-dark;
            
                    &:hover {
                        color: $primary;
                    }
                }
            }
            &.menu-on{
                background: $primary;
                
                .header__links {
                    a {
                        color: $white;
                    }
                
                    .btn-menu {
                        color: $white;
                
                        &:hover {
                            color: $secondary;
                        }
                
                        .icon__menu {
                            visibility: hidden;
                            opacity: 0;
                        }
                
                        .icon__close {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}