.accordion{
    &__title{
        margin-bottom:30px;
        text-align:center;
        font-weight: 700;
        font-size: 25px;
    }
    &__item{
        border-bottom:1px solid $white;
        .item{
            &__title{
                position:relative;
                padding:18px 40px 18px 0;
                font-size:16px;
                cursor:pointer;
                transition:color 0.25s;
                &::after{   
                    content:'+';
                    position:absolute;
                    top:10px;
                    right:5px;
                    font-size:24px;
                    
                }
                &.active{
                    &::after {
                        content: '-';
                        right:8px;
                    }
                }
                &:hover{
                    color:$secondary;
                }
            }
            &__text {
                display:none;
                font-size:16px;
                padding:0 40px 18px 0;
            }
        }
    }
}