@charset "UTF-8";
/*
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
*/
@font-face {
  font-family: 'adrenaline-icons';
  src: url("../fonts/adrenaline-icons.woff2") format("woff2"), url("../fonts/adrenaline-icons.woff") format("woff"), url("../fonts/adrenaline-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'adrenaline-icons';
    src: url('../font/adrenaline-icons.svg?94139383#adrenaline-icons') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "adrenaline-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-phone:before {
  content: '\e800'; }

/* '' */
.icon-logo-adrenaline:before {
  content: '\e801'; }

/* '' */
.icon-engagements:before {
  content: '\e802'; }

/* '' */
.icon-location:before {
  content: '\f031'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-download-cloud:before {
  content: '\f0ed'; }

/* '' */
.icon-arrow-down:before {
  content: '\f175'; }

/* '' */
.icon-arrow-up:before {
  content: '\f176'; }

/* '' */
.icon-arrow-left:before {
  content: '\f177'; }

/* '' */
.icon-arrow-right:before {
  content: '\f178'; }

/* '' */
@font-face {
  font-family: 'Cera pro';
  src: url("../fonts/cera_pro_black.woff2") format("woff2"), url("../fonts/cera_pro_black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Cera pro';
  src: url("../fonts/cera_pro_bold.woff2") format("woff2"), url("../fonts/cera_pro_bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Cera pro';
  src: url("../fonts/cera_pro_light.woff2") format("woff2"), url("../fonts/cera_pro_light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Dexotick';
  src: url("../fonts/dexotick.woff2") format("woff2"), url("../fonts/dexotick.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/material-icons.woff2") format("woff2"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-300-latin-ext.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu0SC55K5gw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-300-latin.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-400-latin-ext.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu0SC55K5gw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-400-latin.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-600-latin-ext.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu0SC55K5gw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-600-latin.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-700-latin-ext.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu0SC55K5gw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-700-latin.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-800-latin-ext.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu0SC55K5gw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/open-sans-800-latin.woff2") format("woff2");
  src: url(https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: "Open Sans", "Helvetica", "arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: #464646; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
  line-height: 1.1; }

p {
  margin: 15px 0; }
  p a {
    color: #E5007D;
    text-decoration: none; }
  p img {
    max-width: 100%;
    height: auto !important; }

ul {
  padding-left: 22px; }
  ul li {
    margin-bottom: 10px; }
    ul li:last-child {
      margin: 0; }
    ul li ul li:first-child {
      margin-top: 10px; }

img {
  max-width: 100%;
  height: auto !important; }

.noscroll {
  overflow-y: hidden;
  margin-right: 17px; }
  @media (max-width: 1023px) {
    .noscroll {
      margin-right: 0; } }

.center {
  text-align: center; }

.italic {
  font-style: italic; }

.justify-center {
  justify-content: center; }

@media (max-width: 768px) {
  .hide-mobile {
    display: none !important; } }

.text-primary {
  color: #E5007D !important; }

.text-secondary {
  color: #FCED4E !important; }

.text-white {
  color: #ffffff !important; }

.text-grey {
  color: #464646 !important; }

.bg-primary {
  background-color: #E5007D; }

.bg-secondary {
  background-color: #FCED4E; }

.bg-white {
  background-color: #ffffff; }

.bg-grey {
  background-color: #464646; }

.bg-grey-light {
  background-color: #FBF9F6; }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .row--vertical-center {
    align-items: center; }
  @media (max-width: 768px) {
    .row.mob-no-padding {
      margin-left: 0;
      margin-right: 0; }
      .row.mob-no-padding > div {
        padding-left: 0;
        padding-right: 0; } }

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-1-5,
.col-2-5,
.col-3-5,
.col-4-5 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.col-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%; }

.col-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%; }

.col-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%; }

.col-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%; }

.col-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%; }

.col-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%; }

.col-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%; }

.col-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%; }

.col-12 {
  flex-basis: 100%;
  max-width: 100%; }

.col-1-5 {
  flex-basis: 20%;
  max-width: 20%; }

.col-2-5 {
  flex-basis: 40%;
  max-width: 40%; }

.col-3-5 {
  flex-basis: 60%;
  max-width: 60%; }

.col-4-5 {
  flex-basis: 80%;
  max-width: 80%; }

@media (max-width: 1200px) {
  .col-desksm-1,
  .col-desksm-2,
  .col-desksm-3,
  .col-desksm-4,
  .col-desksm-5,
  .col-desksm-6,
  .col-desksm-7,
  .col-desksm-8,
  .col-desksm-9,
  .col-desksm-10,
  .col-desksm-11,
  .col-desksm-12,
  .col-desksm-1-5,
  .col-desksm-2-5,
  .col-desksm-3-5,
  .col-desksm-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px; }
  .col-desksm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-desksm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-desksm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-desksm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-desksm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-desksm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-desksm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-desksm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-desksm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-desksm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-desksm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-desksm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-desksm-1-5 {
    flex-basis: 20%;
    max-width: 20%; }
  .col-desksm-2-5 {
    flex-basis: 40%;
    max-width: 40%; }
  .col-desksm-3-5 {
    flex-basis: 60%;
    max-width: 60%; }
  .col-desksm-4-5 {
    flex-basis: 80%;
    max-width: 80%; } }

@media (max-width: 1023px) {
  .col-tab-1,
  .col-tab-2,
  .col-tab-3,
  .col-tab-4,
  .col-tab-5,
  .col-tab-6,
  .col-tab-7,
  .col-tab-8,
  .col-tab-9,
  .col-tab-10,
  .col-tab-11,
  .col-tab-12,
  .col-tab-1-5,
  .col-tab-2-5,
  .col-tab-3-5,
  .col-tab-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px; }
  .col-tab-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-tab-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-tab-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-tab-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-tab-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-tab-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-tab-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-tab-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-tab-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-tab-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-tab-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-tab-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-tab-1-5 {
    flex-basis: 20%;
    max-width: 20%; }
  .col-tab-2-5 {
    flex-basis: 40%;
    max-width: 40%; }
  .col-tab-3-5 {
    flex-basis: 60%;
    max-width: 60%; }
  .col-tab-4-5 {
    flex-basis: 80%;
    max-width: 80%; } }

@media (max-width: 768px) {
  .col-mob-1,
  .col-mob-2,
  .col-mob-3,
  .col-mob-4,
  .col-mob-5,
  .col-mob-6,
  .col-mob-7,
  .col-mob-8,
  .col-mob-9,
  .col-mob-10,
  .col-mob-11,
  .col-mob-12,
  .col-mob-1-5,
  .col-mob-2-5,
  .col-mob-3-5,
  .col-mob-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px; }
  .col-mob-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-mob-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-mob-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-mob-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-mob-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-mob-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-mob-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-mob-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-mob-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-mob-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-mob-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-mob-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-mob-1-5 {
    flex-basis: 20%;
    max-width: 20%; }
  .col-mob-2-5 {
    flex-basis: 40%;
    max-width: 40%; }
  .col-mob-3-5 {
    flex-basis: 60%;
    max-width: 60%; }
  .col-mob-4-5 {
    flex-basis: 80%;
    max-width: 80%; } }

.main-wrap {
  width: 100%;
  margin: auto; }

.wrap {
  width: 100%;
  max-width: 1340px;
  margin: auto;
  padding: 0 30px;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .wrap {
      padding: 0 15px; } }

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 45px;
  padding: 10px 20px;
  box-sizing: border-box;
  background: #E5007D;
  border: 1px solid #E5007D;
  border-radius: 45px;
  color: #ffffff;
  font-size: 21px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.25s;
  cursor: pointer; }
  .button__text {
    transition: opacity 0.25s; }
  .button__text-hover {
    position: absolute;
    opacity: 0;
    transition: opacity 0.25s; }
  .button:hover, .button.active {
    border-color: #E5007D;
    background: #FCED4E;
    color: #E5007D; }
    .button:hover .button__text, .button.active .button__text {
      opacity: 0; }
    .button:hover .button__text-hover, .button.active .button__text-hover {
      opacity: 1; }
  .button--xsm {
    padding: 6px 10px;
    min-height: 36px;
    font-size: 14px; }
  .button--sm {
    padding: 8px 20px;
    min-height: 40px;
    font-size: 16px; }
  .button--big {
    padding: 8px 25px;
    min-height: 50px;
    font-size: 26px; }
  .button--white {
    background: #ffffff;
    color: #E5007D;
    border-color: #ffffff; }
    .button--white:hover, .button--white.active {
      border-color: #FCED4E; }
  .button--white-bordered {
    background: #ffffff;
    color: #E5007D;
    border-color: #E5007D; }
  .button--outline {
    background: transparent;
    color: #E5007D; }
  .button--outline-white {
    background: transparent;
    color: #ffffff;
    border-color: #ffffff; }

.separator {
  padding-top: 80px; }
  @media (max-width: 768px) {
    .separator {
      padding-top: 50px; } }

.highlight {
  position: relative;
  display: inline-block;
  font-family: "Dexotick", sans-serif;
  color: #FCED4E;
  transform: rotate(-3.65deg); }
  .highlight:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -35%;
    left: calc(50% - 88px);
    width: 177px;
    height: 38px;
    background: url(../images/trait.svg) no-repeat;
    background-size: contain;
    transform: rotate(3.65deg); }

.title-1 {
  font-family: "Cera pro", sans-serif;
  color: #E5007D;
  font-size: 62px;
  text-transform: uppercase; }

.title-2 {
  margin-bottom: 40px;
  font-family: "Cera pro", sans-serif;
  font-weight: 900;
  color: #E5007D;
  font-size: 50px; }
  @media (max-width: 768px) {
    .title-2 {
      font-size: 36px; } }
  .title-2:after {
    content: '';
    margin-top: 10px;
    display: block;
    width: 34px;
    border-bottom: 3px solid #E5007D; }
  .title-2.center:after {
    margin: 15px auto 0; }
  .title-2.text-white:after {
    border-color: #ffffff; }

.carousel {
  width: 100%;
  max-width: 560px;
  margin: 0 auto 50px auto;
  overflow: hidden;
  filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.12)); }
  .carousel img {
    margin: 0 !important; }
  .carousel .slick-dots {
    display: flex;
    justify-content: center;
    list-style: none; }
    .carousel .slick-dots li {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      border-radius: 12px;
      border: 1px solid #E5007D;
      cursor: pointer;
      transition: all 0.25s; }
      .carousel .slick-dots li:hover {
        background: #E5007D; }
      .carousel .slick-dots li.slick-active {
        background: #FCED4E; }
      .carousel .slick-dots li button {
        display: none; }

.link-download {
  display: inline-block;
  text-decoration: none;
  position: relative;
  margin-bottom: 10px;
  padding: 10px 0 0 60px; }
  .link-download i {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 35px;
    color: #E5007D; }
    .link-download i.white {
      color: #ffffff; }
    .link-download i.grey {
      color: #464646; }
    .link-download i.black {
      color: #000000; }
    .link-download i.yellow {
      color: #FCED4E; }
    .link-download i.pink {
      color: #E5007D; }
  .link-download span {
    font-size: 18px;
    text-decoration: underline;
    color: #E5007D; }
    .link-download span.white {
      color: #ffffff; }
    .link-download span.grey {
      color: #464646; }
    .link-download span.black {
      color: #000000; }
    .link-download span.yellow {
      color: #FCED4E; }
    .link-download span.pink {
      color: #E5007D; }
  .link-download:hover span {
    text-decoration: none; }

.link-arrow a {
  display: inline-flex;
  align-items: center;
  color: #E5007D;
  text-decoration: none !important;
  font-size: 400 !important; }
  .link-arrow a span {
    text-decoration: underline; }
  .link-arrow a i {
    margin-left: 5px;
    font-size: 30px;
    transition: all 0.25s;
    text-decoration: none; }
  .link-arrow a:hover i {
    transform: translateX(10px); }

.link-arrow.white a {
  color: #ffffff !important; }

.link-arrow.grey a {
  color: #7C7C7C !important; }

.link-arrow.black a {
  color: #000000 !important; }

.link-arrow.pink a {
  color: #E5007D !important; }

.link-arrow.yellow a {
  color: #FCED4E !important; }

.accordion__title {
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
  font-size: 25px; }

.accordion__item {
  border-bottom: 1px solid #ffffff; }
  .accordion__item .item__title {
    position: relative;
    padding: 18px 40px 18px 0;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.25s; }
    .accordion__item .item__title::after {
      content: '+';
      position: absolute;
      top: 10px;
      right: 5px;
      font-size: 24px; }
    .accordion__item .item__title.active::after {
      content: '-';
      right: 8px; }
    .accordion__item .item__title:hover {
      color: #FCED4E; }
  .accordion__item .item__text {
    display: none;
    font-size: 16px;
    padding: 0 40px 18px 0; }

.home-intro {
  position: relative;
  height: calc(100vh - 110px);
  background: #E5007D; }
  @media (max-width: 768px) {
    .home-intro {
      display: flex;
      align-items: center;
      height: calc(100vh - 60px); } }
  .home-intro__content {
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center; }
    @media (max-width: 768px) {
      .home-intro__content {
        position: relative; } }
    .home-intro__content .content__img img {
      height: calc(100% - 70px) !important;
      width: auto !important; }
      @media (max-width: 768px) {
        .home-intro__content .content__img img {
          height: auto !important;
          width: 100% !important; } }
    .home-intro__content .content__text {
      position: absolute;
      top: 20vh;
      left: 0;
      width: 100%;
      text-align: center; }
      @media (max-width: 768px) {
        .home-intro__content .content__text {
          top: 15vw; } }
      .home-intro__content .content__text .text__title {
        font-family: "Cera pro", sans-serif;
        font-size: 62px;
        line-height: 96px;
        font-weight: 900;
        color: #ffffff;
        text-shadow: 0px 4px 18px rgba(0, 0, 0, 0.68); }
        @media (max-width: 768px) {
          .home-intro__content .content__text .text__title {
            font-size: 35px;
            line-height: 56px; } }
        .home-intro__content .content__text .text__title .highlight {
          font-size: 136px;
          font-weight: 400; }
          @media (max-width: 768px) {
            .home-intro__content .content__text .text__title .highlight {
              font-size: 86px;
              line-height: 56px; } }
          @media (max-width: 768px) {
            .home-intro__content .content__text .text__title .highlight:after {
              bottom: -65%;
              left: calc(50% - 75px);
              width: 140px; } }
      .home-intro__content .content__text .text__button {
        margin-top: 20px; }
        @media (max-width: 768px) {
          .home-intro__content .content__text .text__button {
            display: none; } }
        .home-intro__content .content__text .text__button .button {
          width: 350px; }
    @media (max-width: 768px) {
      .home-intro__content .content__title {
        padding-bottom: 25px;
        margin-top: 50px; } }
    .home-intro__content .content__title a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Cera pro", sans-serif;
      font-weight: bold;
      font-size: 17px;
      color: #ffffff;
      color: #ffffff;
      text-decoration: none; }
      @media (max-width: 768px) {
        .home-intro__content .content__title a {
          flex-direction: column; } }
    .home-intro__content .content__title i {
      font-size: 34px;
      transform: rotate(90deg); }
  .home-intro__deco-left {
    position: absolute;
    top: 150px;
    left: 0;
    width: 238px;
    height: 316px;
    background: url(../images/home-intro-left@2x.png);
    background-size: contain; }
    @media (max-width: 1023px) {
      .home-intro__deco-left {
        display: none; } }
  .home-intro__deco-right {
    position: absolute;
    top: 80px;
    right: 0;
    width: 390px;
    height: 402px;
    background: url(../images/home-intro-right@2x.png);
    background-size: contain; }
    @media (max-width: 1023px) {
      .home-intro__deco-right {
        display: none; } }

.contact {
  padding: 80px 0;
  background: #E5007D;
  text-align: center;
  color: #ffffff; }
  @media (max-width: 768px) {
    .contact {
      padding: 50px 0; } }
  .contact p {
    margin-top: 40px;
    font-size: 28px;
    font-weight: bold; }
    @media (max-width: 1023px) {
      .contact p {
        font-size: 23px; } }
    @media (max-width: 768px) {
      .contact p {
        font-size: 18px; } }
  .contact__arrow {
    margin-bottom: 20px;
    transform: rotate(90deg); }
    .contact__arrow i {
      font-size: 64px; }
      @media (max-width: 768px) {
        .contact__arrow i {
          font-size: 54px; } }

.clients {
  padding: 80px 0; }
  @media (max-width: 768px) {
    .clients {
      padding: 50px 0; } }
  .clients__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .clients__list .list__item {
      width: 25%;
      text-align: center;
      margin: 20px 0; }
      @media (max-width: 1023px) {
        .clients__list .list__item {
          width: 30%; } }
      @media (max-width: 768px) {
        .clients__list .list__item {
          width: 45%; } }

.adn {
  padding: 80px 0;
  background: #E5007D;
  color: #ffffff; }
  @media (max-width: 768px) {
    .adn {
      padding: 50px 0; } }
  @media (max-width: 768px) {
    .adn .wrap {
      padding: 0; } }
  .adn__slider .slider__item {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .adn__slider .slider__item {
        flex-direction: column-reverse;
        padding: 0 15px; } }
    .adn__slider .slider__item .item__text {
      width: calc(50% - 25px);
      padding-left: 50px;
      box-sizing: border-box;
      font-weight: 300; }
      @media (max-width: 768px) {
        .adn__slider .slider__item .item__text {
          width: 100%;
          padding-left: 0; } }
      .adn__slider .slider__item .item__text h2 {
        margin-bottom: 50px;
        font-family: "Cera pro", sans-serif;
        font-size: 40px;
        font-weight: bold; }
        @media (max-width: 768px) {
          .adn__slider .slider__item .item__text h2 {
            font-size: 30px;
            margin-bottom: 40px; } }
        .adn__slider .slider__item .item__text h2 .highlight {
          font-weight: 400;
          font-size: 99px; }
          @media (max-width: 768px) {
            .adn__slider .slider__item .item__text h2 .highlight {
              font-size: 69px;
              padding-left: 20px; } }
      .adn__slider .slider__item .item__text p {
        margin: 20px 0;
        font-size: 19px; }
        @media (max-width: 768px) {
          .adn__slider .slider__item .item__text p {
            font-size: 17px; } }
        .adn__slider .slider__item .item__text p a {
          color: #ffffff;
          text-decoration: underline; }
      .adn__slider .slider__item .item__text .text__link {
        display: inline-flex;
        align-items: center;
        margin-top: 10px;
        font-size: 19px;
        color: #ffffff;
        text-decoration: none;
        transition: color 0.25s; }
        @media (max-width: 768px) {
          .adn__slider .slider__item .item__text .text__link {
            font-size: 17px; } }
        .adn__slider .slider__item .item__text .text__link span {
          text-decoration: underline; }
        .adn__slider .slider__item .item__text .text__link i {
          margin-left: 10px;
          font-size: 36px;
          transition: transform 0.25s; }
        .adn__slider .slider__item .item__text .text__link:hover {
          color: #FCED4E; }
          .adn__slider .slider__item .item__text .text__link:hover i {
            transform: translateX(10px); }
    .adn__slider .slider__item .item__img {
      display: flex;
      justify-content: center;
      width: calc(50% - 25px); }
      @media (max-width: 768px) {
        .adn__slider .slider__item .item__img {
          width: 100%; } }
      @media (max-width: 768px) {
        .adn__slider .slider__item .item__img img {
          max-width: 100%;
          align-self: flex-start; } }
  .adn__slider .slick-dots {
    display: flex;
    justify-content: center;
    list-style: none; }
    .adn__slider .slick-dots li {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      border-radius: 12px;
      border: 1px solid #ffffff;
      cursor: pointer;
      transition: all 0.25s; }
      .adn__slider .slick-dots li:hover, .adn__slider .slick-dots li.slick-active {
        border-color: #FCED4E;
        background: #FCED4E; }
      .adn__slider .slick-dots li button {
        display: none; }

.definition {
  padding: 80px 0;
  background: #ffffff; }
  @media (max-width: 768px) {
    .definition {
      padding: 50px 0; } }
  .definition .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .definition__left {
    width: calc(50% - 30px); }
    @media (max-width: 1023px) {
      .definition__left {
        width: 100%; } }
    .definition__left .left__definition {
      font-weight: 600;
      font-size: 24px;
      line-height: 38px; }
      @media (max-width: 1023px) {
        .definition__left .left__definition {
          font-size: 19px;
          line-height: 28px; } }
      .definition__left .left__definition:before {
        content: '\25FC'; }
    .definition__left .left__text {
      margin-top: 50px; }
      @media (max-width: 1023px) {
        .definition__left .left__text {
          margin-top: 35px; } }
      .definition__left .left__text p {
        font-size: 18px; }
        @media (max-width: 1023px) {
          .definition__left .left__text p {
            font-size: 17px; } }
  .definition__right {
    width: calc(50% - 90px); }
    @media (max-width: 1023px) {
      .definition__right {
        display: none; } }

.expertises {
  padding: 80px 0; }
  @media (max-width: 768px) {
    .expertises {
      padding: 50px 0; } }
  .expertises__content {
    position: relative;
    width: 1000px;
    height: 936px;
    margin: auto; }
    @media (max-width: 1023px) {
      .expertises__content {
        width: 100%;
        height: 100vw; } }
    .expertises__content .content__item {
      position: absolute;
      text-decoration: none; }
      .expertises__content .content__item > div {
        position: relative; }
        .expertises__content .content__item > div > div {
          transition: transform .25s; }
        .expertises__content .content__item > div h2 {
          position: absolute;
          display: inline-block;
          color: #ffffff;
          font-family: "Cera pro", sans-serif;
          font-size: 55px;
          font-weight: 900;
          text-transform: uppercase;
          text-align: center;
          transition: all .25s; }
          @media (max-width: 1023px) {
            .expertises__content .content__item > div h2 {
              font-size: 5.33vw; } }
      .expertises__content .content__item--pub {
        top: 80px;
        left: 0; }
        @media (max-width: 1023px) {
          .expertises__content .content__item--pub {
            top: 7.76vw; } }
        .expertises__content .content__item--pub > div {
          width: 459px;
          height: 424px; }
          @media (max-width: 1023px) {
            .expertises__content .content__item--pub > div {
              width: 42.56vw;
              height: 41.16vw; } }
          .expertises__content .content__item--pub > div > div {
            width: 459px;
            height: 424px;
            background: url(../images/expertise-pub@2x.png) no-repeat center;
            background-size: contain; }
            @media (max-width: 1023px) {
              .expertises__content .content__item--pub > div > div {
                width: 42.56vw;
                height: 41.16vw; } }
          .expertises__content .content__item--pub > div h2 {
            top: 124px; }
            @media (max-width: 1023px) {
              .expertises__content .content__item--pub > div h2 {
                top: 12.03vw; } }
      .expertises__content .content__item--branding {
        top: 0;
        right: 0; }
        .expertises__content .content__item--branding > div {
          width: 498px;
          height: 442px; }
          @media (max-width: 1023px) {
            .expertises__content .content__item--branding > div {
              width: 42.34vw;
              height: 42.91vw; } }
          .expertises__content .content__item--branding > div > div {
            width: 498px;
            height: 442px;
            background: url(../images/expertise-branding@2x.png) no-repeat center;
            background-size: contain; }
            @media (max-width: 1023px) {
              .expertises__content .content__item--branding > div > div {
                width: 42.34vw;
                height: 42.91vw; } }
          .expertises__content .content__item--branding > div h2 {
            position: absolute;
            top: 128px; }
            @media (max-width: 1023px) {
              .expertises__content .content__item--branding > div h2 {
                top: 12.42vw; } }
      .expertises__content .content__item--digitale {
        top: 492px;
        left: 28px; }
        @media (max-width: 1023px) {
          .expertises__content .content__item--digitale {
            top: 47.76vw;
            left: 2.71vw; } }
        .expertises__content .content__item--digitale > div {
          width: 452px;
          height: 446px; }
          @media (max-width: 1023px) {
            .expertises__content .content__item--digitale > div {
              width: 43.88vw;
              height: 43.30vw; } }
          .expertises__content .content__item--digitale > div > div {
            width: 452px;
            height: 446px;
            background: url(../images/expertise-digitale@2x.png) no-repeat center;
            background-size: contain; }
            @media (max-width: 1023px) {
              .expertises__content .content__item--digitale > div > div {
                width: 43.88vw;
                height: 43.30vw; } }
          .expertises__content .content__item--digitale > div h2 {
            top: 134px; }
            @media (max-width: 1023px) {
              .expertises__content .content__item--digitale > div h2 {
                top: 13vw; } }
      .expertises__content .content__item--marketing {
        top: 445px;
        right: 25px; }
        @media (max-width: 1023px) {
          .expertises__content .content__item--marketing {
            top: 43.2vw;
            right: 2.42vw; } }
        .expertises__content .content__item--marketing > div {
          width: 455px;
          height: 450px; }
          @media (max-width: 1023px) {
            .expertises__content .content__item--marketing > div {
              width: 40.17vw;
              height: 43.68vw; } }
          .expertises__content .content__item--marketing > div > div {
            width: 455px;
            height: 450px;
            background: url(../images/expertise-marketing@2x.png) no-repeat center;
            background-size: contain; }
            @media (max-width: 1023px) {
              .expertises__content .content__item--marketing > div > div {
                width: 40.17vw;
                height: 43.68vw; } }
          .expertises__content .content__item--marketing > div h2 {
            top: 116px; }
            @media (max-width: 1023px) {
              .expertises__content .content__item--marketing > div h2 {
                top: 11.26vw; } }
      .expertises__content .content__item:hover > div > div {
        transform: rotate(8deg); }
      .expertises__content .content__item:hover > div h2 {
        color: #FCED4E;
        transform: translateY(15px); }
        @media (max-width: 1023px) {
          .expertises__content .content__item:hover > div h2 {
            transform: translateY(1.4vw); } }

.main-title {
  background: #E5007D;
  color: #ffffff; }
  .main-title .wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 224px;
    padding-top: 55px;
    padding-bottom: 30px;
    box-sizing: border-box; }
  .main-title__title {
    padding-top: 20px;
    font-size: 61px;
    font-weight: 900;
    text-transform: uppercase;
    transform: translateY(-15px);
    text-align: center; }
    @media (max-width: 768px) {
      .main-title__title {
        font-size: 32px; } }
    .main-title__title .highlight {
      font-size: 100px;
      font-weight: 400;
      text-transform: none; }
      @media (max-width: 768px) {
        .main-title__title .highlight {
          font-size: 70px; } }
      .main-title__title .highlight::after {
        bottom: -20px; }
        @media (max-width: 768px) {
          .main-title__title .highlight::after {
            left: calc(50% - 60px);
            width: 120px; } }
  .main-title__return {
    position: absolute;
    top: 20px;
    left: 30px;
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.25s; }
    .main-title__return i {
      margin-right: 10px;
      transform: rotate(180deg);
      font-size: 32px; }
    .main-title__return:hover {
      color: #FCED4E; }
  .main-title__theme {
    margin-bottom: 20px;
    padding: 5px 25px;
    background: #FCED4E;
    color: #E5007D;
    font-size: 16px;
    font-weight: 700; }
  .main-title__date {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; }

.filters .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px; }
  @media (max-width: 1023px) {
    .filters .wrap {
      display: block;
      margin-top: 50px;
      text-align: center; } }

.filters__list {
  margin: 0 15px; }
  @media (max-width: 1023px) {
    .filters__list {
      margin: 15px 0; } }

.filters__see-all {
  text-decoration: underline;
  transition: color 0.25s;
  text-decoration: underline;
  cursor: pointer; }
  .filters__see-all:hover {
    color: #E5007D; }

.filters .filter-button {
  margin: 5px; }

.to-filter.hide {
  transform: scale(0);
  transition: transform 0.25s; }

.to-filter.hidden {
  display: none !important; }

.blog {
  margin-bottom: 90px; }
  .blog .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .blog__item {
    position: relative;
    width: calc(50% - 11px);
    margin-top: 90px;
    transition: transform 0.25s; }
    @media (max-width: 1023px) {
      .blog__item {
        width: 100%;
        margin-top: 50px; } }
    .blog__item .item__top {
      position: relative;
      border-radius: 30px; }
      .blog__item .item__top:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0) 35%);
        border-radius: 30px;
        transition: all 0.25s; }
      .blog__item .item__top .top__img {
        display: block;
        width: 100%;
        height: 380px !important;
        object-fit: cover;
        border-radius: 30px; }
        @media (max-width: 768px) {
          .blog__item .item__top .top__img {
            width: 100%;
            height: 230px !important; } }
      .blog__item .item__top .top__theme {
        position: absolute;
        top: 30px;
        left: 0;
        padding: 5px 25px;
        background: #FCED4E;
        color: #E5007D;
        font-size: 16px;
        font-weight: bold; }
      .blog__item .item__top .top__meta {
        position: absolute;
        bottom: 30px;
        left: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center; }
        .blog__item .item__top .top__meta .meta__item {
          display: flex;
          align-items: center;
          margin-right: 30px;
          color: #ffffff;
          font-weight: bold; }
          .blog__item .item__top .top__meta .meta__item i {
            margin-right: 8px; }
      .blog__item .item__top:hover:before {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 5%, rgba(0, 0, 0, 0.25) 100%); }
    .blog__item .item__title {
      margin-top: 20px;
      font-size: 30px;
      font-weight: bold; }
    .blog__item .item__excerpt {
      font-weight: 300;
      line-height: 25px; }
    .blog__item .item__link {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      color: #E5007D;
      text-decoration: none; }
      .blog__item .item__link span {
        text-decoration: underline; }
      .blog__item .item__link i {
        margin-left: 5px;
        font-size: 28px;
        transition: all 0.25s; }
      .blog__item .item__link:hover i {
        transform: translateX(10px); }
    .blog__item .item__link-global {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.last-projects {
  padding: 80px 0; }
  @media (max-width: 768px) {
    .last-projects {
      padding: 50px 0; } }
  .last-projects__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    @media (max-width: 1023px) {
      .last-projects__content {
        justify-content: center; } }
    .last-projects__content .content__item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc( (100% / 3) - 20px);
      height: 362px;
      margin: 0 10px 20px 10px;
      box-sizing: border-box;
      padding: 25px;
      border-radius: 30px;
      background-size: cover;
      background-position: center; }
      @media (max-width: 1023px) {
        .last-projects__content .content__item {
          width: calc(50% - 20px);
          margin: 10px 10px; } }
      @media (max-width: 768px) {
        .last-projects__content .content__item {
          width: 100%; } }
      .last-projects__content .content__item .item__logo {
        transition: opacity 0.25s; }
      .last-projects__content .content__item .item__details {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.25s; }
        @media (max-width: 768px) {
          .last-projects__content .content__item .item__details {
            display: none; } }
        .last-projects__content .content__item .item__details > div {
          padding: 20px;
          color: #ffffff;
          text-align: center;
          transform: scale(0);
          transition: transform 0.25s; }
          .last-projects__content .content__item .item__details > div .details__title {
            font-family: "Cera pro", sans-serif;
            font-size: 50px;
            font-weight: bold;
            text-transform: uppercase; }
          .last-projects__content .content__item .item__details > div .details__subtitle {
            margin-top: 5px;
            font-size: 30px;
            font-weight: 300; }
          .last-projects__content .content__item .item__details > div .details__link {
            position: relative;
            z-index: 1;
            display: inline-flex;
            align-items: center;
            margin-top: 10px;
            font-size: 16px;
            color: #ffffff;
            text-decoration: none; }
            .last-projects__content .content__item .item__details > div .details__link span {
              text-decoration: underline; }
            .last-projects__content .content__item .item__details > div .details__link i {
              margin-left: 7px;
              font-size: 30px;
              transition: transform 0.25s; }
            .last-projects__content .content__item .item__details > div .details__link:hover i {
              transform: translateX(5px); }
          .last-projects__content .content__item .item__details > div .details__tags {
            margin-top: 30px;
            display: flex;
            justify-content: center; }
            .last-projects__content .content__item .item__details > div .details__tags .tags__item {
              margin: 0 10px 10px 10px;
              font-size: 14px;
              font-weight: bold; }
      .last-projects__content .content__item .item__link {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%; }
      .last-projects__content .content__item:hover .item__logo {
        opacity: 0; }
        @media (max-width: 768px) {
          .last-projects__content .content__item:hover .item__logo {
            opacity: 1; } }
      .last-projects__content .content__item:hover .item__details {
        opacity: 1; }
        .last-projects__content .content__item:hover .item__details > div {
          transform: scale(1); }
  .last-projects__button {
    margin-top: 40px;
    text-align: center; }

.contact-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  background: #E5007D;
  text-align: center;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2); }
  @media (max-width: 768px) {
    .contact-mobile {
      display: flex; } }

.contact-agences {
  padding: 80px 0; }
  .contact-agences .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .contact-agences .wrap {
        justify-content: center; } }
  .contact-agences__item {
    width: calc( (100% / 3) -  20px);
    margin-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.12); }
    @media (max-width: 1023px) {
      .contact-agences__item {
        width: calc(50% - 20px);
        margin: 0 10px 20px 10px; } }
    @media (max-width: 768px) {
      .contact-agences__item {
        width: 100%; } }
    .contact-agences__item .item__img {
      display: block;
      width: 100%;
      height: 270px !important;
      object-fit: cover;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px; }
      @media (max-width: 768px) {
        .contact-agences__item .item__img {
          height: 215px !important; } }
    .contact-agences__item .item__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: calc(100% - 270px);
      box-sizing: border-box;
      padding: 30px 80px;
      background: #E5007D;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px; }
      @media (max-width: 768px) {
        .contact-agences__item .item__content {
          height: calc(100% - 215px);
          padding: 30px 20px; } }
      .contact-agences__item .item__content .content__agence {
        display: inline-block;
        margin-bottom: 20px;
        text-align: left;
        color: #ffffff; }
        .contact-agences__item .item__content .content__agence .agence__city {
          margin-bottom: 20px;
          text-transform: uppercase;
          font-size: 25px;
          font-weight: 600; }
          .contact-agences__item .item__content .content__agence .agence__city a {
            color: #ffffff;
            text-decoration: none; }
        .contact-agences__item .item__content .content__agence .agence__text {
          display: flex;
          margin-top: 5px;
          font-size: 18px;
          line-height: 1.5;
          color: #ffffff;
          text-decoration: none; }
          .contact-agences__item .item__content .content__agence .agence__text i {
            margin-right: 5px;
            font-size: 23px; }

.article {
  padding: 80px 0; }
  @media (max-width: 768px) {
    .article {
      padding: 50px 0; } }
  .article .wrap {
    max-width: 1060px; }
  .article img {
    max-width: 100%; }
  .article h2 {
    margin: 50px 0 25px 0;
    font-weight: bold;
    font-size: 40px; }
    .article h2:first-of-type {
      margin-top: 0; }
    .article h2 a {
      text-decoration: underline; }
      .article h2 a:hover {
        text-decoration: none; }
  .article h3 a {
    text-decoration: underline; }
    .article h3 a:hover {
      text-decoration: none; }
  .article p {
    margin: 25px 0;
    font-size: 18px; }
    .article p a {
      text-decoration: underline; }
      .article p a:hover {
        text-decoration: none; }
  .article ul li {
    margin: 18px 0;
    font-size: 18px; }
    .article ul li a {
      text-decoration: underline;
      color: #E5007D; }
      .article ul li a:hover {
        text-decoration: none; }
  .article__share {
    margin-top: 60px;
    font-size: 16px;
    text-align: right; }

.the_champ_sharing_title {
  margin-top: 50px; }

.last-articles {
  padding: 0 0 80px 0; }
  @media (max-width: 768px) {
    .last-articles {
      padding: 0 0 50px 0; } }
  .last-articles .wrap {
    max-width: 1060px; }
  .last-articles__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 80px;
    border-top: 1px solid #000000; }
    @media (max-width: 768px) {
      .last-articles__header {
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 50px; } }
    .last-articles__header .header__title {
      font-size: 30px;
      font-weight: bold; }
    .last-articles__header .header__link {
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      color: #E5007D;
      text-decoration: none; }
      .last-articles__header .header__link span {
        text-decoration: underline; }
      .last-articles__header .header__link i {
        margin-left: 5px;
        font-size: 32px;
        transition: all 0.25s; }
      .last-articles__header .header__link:hover i {
        transform: translateX(10px); }
  .last-articles__content {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .last-articles__content {
        flex-wrap: wrap; } }
    .last-articles__content .content__item {
      width: calc(25% - 20px);
      margin-top: 40px; }
      @media (max-width: 1023px) {
        .last-articles__content .content__item {
          width: calc(50% - 20px); } }
      @media (max-width: 768px) {
        .last-articles__content .content__item {
          width: 100%; } }
      .last-articles__content .content__item .item__img {
        border-radius: 30px;
        height: 147px !important;
        object-fit: cover; }
        @media (max-width: 1023px) {
          .last-articles__content .content__item .item__img {
            width: 100%;
            height: 200px !important; } }
        @media (max-width: 768px) {
          .last-articles__content .content__item .item__img {
            height: 147px !important; } }
      .last-articles__content .content__item .item__title {
        margin: 20px 0 10px 0;
        font-weight: 700;
        font-size: 26px; }
      .last-articles__content .content__item .item__link {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color: #E5007D;
        text-decoration: none; }
        .last-articles__content .content__item .item__link span {
          text-decoration: underline; }
        .last-articles__content .content__item .item__link i {
          margin-left: 5px;
          font-size: 28px;
          transition: all 0.25s; }
        .last-articles__content .content__item .item__link:hover i {
          transform: translateX(10px); }

.anchors {
  background: #F8F7F7; }
  @media (max-width: 768px) {
    .anchors {
      display: none; } }
  .anchors .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px; }
    @media (max-width: 1023px) {
      .anchors .wrap {
        flex-wrap: wrap;
        height: 140px; } }
  .anchors__item {
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: 13px;
    font-weight: 600;
    color: #464646;
    text-decoration: none; }
    @media (max-width: 1023px) {
      .anchors__item {
        width: calc(100% / 3);
        box-sizing: border-box; } }
    @media (max-width: 768px) {
      .anchors__item {
        font-size: 13px; } }
    .anchors__item:first-child {
      padding-left: 0; }
    @media (max-width: 1023px) {
      .anchors__item:nth-child(2) {
        justify-content: center; } }
    @media (max-width: 1023px) {
      .anchors__item:nth-child(3) {
        padding-right: 0;
        justify-content: flex-end; } }
    @media (max-width: 1023px) {
      .anchors__item:nth-child(4) {
        padding-left: 0; } }
    @media (max-width: 1023px) {
      .anchors__item:nth-child(5) {
        justify-content: center; } }
    .anchors__item:last-child {
      padding-right: 0; }
      @media (max-width: 1023px) {
        .anchors__item:last-child {
          justify-content: flex-end; } }
    .anchors__item img {
      display: block;
      margin-right: 10px; }
      @media (max-width: 768px) {
        .anchors__item img {
          max-width: 25px; } }

.intro-text-img .wrap {
  display: flex; }
  @media (max-width: 768px) {
    .intro-text-img .wrap {
      flex-direction: column-reverse; } }

.intro-text-img__text {
  width: 50%;
  padding: 50px 0;
  color: #000000; }
  @media (max-width: 768px) {
    .intro-text-img__text {
      width: 100%; } }
  .intro-text-img__text h1, .intro-text-img__text h2 {
    margin-bottom: 40px;
    font-size: 38px;
    font-weight: bold; }
  .intro-text-img__text p {
    margin: 30px 0;
    text-align: justify;
    font-weight: 300;
    font-size: 18px; }
    .intro-text-img__text p strong, .intro-text-img__text p b {
      font-weight: 600; }
    .intro-text-img__text p a {
      font-weight: 400;
      color: #E5007D;
      text-decoration: underline; }
      .intro-text-img__text p a:hover {
        text-decoration: none; }

.intro-text-img__img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  padding-left: 50px; }
  @media (max-width: 768px) {
    .intro-text-img__img {
      width: 100%;
      padding: 50px 0 0 0; } }

.intro-agence .wrap {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .intro-agence .wrap {
      flex-direction: column; } }

.intro-agence__text {
  width: 100%;
  max-width: 690px;
  margin-right: 50px;
  padding: 50px 0;
  color: #000000; }
  @media (max-width: 768px) {
    .intro-agence__text {
      width: 100%; } }
  .intro-agence__text h1, .intro-agence__text h2 {
    margin-bottom: 40px;
    font-size: 38px;
    font-weight: bold; }
  .intro-agence__text p {
    margin: 30px 0;
    text-align: justify;
    font-weight: 300;
    font-size: 18px; }
    .intro-agence__text p strong, .intro-agence__text p b {
      font-weight: 600; }
    .intro-agence__text p a {
      font-weight: 400;
      color: #E5007D;
      text-decoration: underline; }
      .intro-agence__text p a:hover {
        text-decoration: none; }

.intro-agence__infos {
  width: 100%;
  max-width: 464px;
  padding: 50px 0; }
  .intro-agence__infos .infos__top {
    padding: 30px 20px;
    border-radius: 20px 20px 0 0;
    background: #E5007D;
    color: #ffffff;
    text-align: center; }
    .intro-agence__infos .infos__top .top__text {
      font-size: 20px;
      font-weight: bold; }
    .intro-agence__infos .infos__top .top__arrow i {
      font-size: 64px;
      transform: rotate(90deg); }
    .intro-agence__infos .infos__top .top__agence {
      display: inline-block;
      margin-bottom: 20px;
      text-align: left; }
      .intro-agence__infos .infos__top .top__agence .agence__city {
        margin-bottom: 20px;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 600; }
      .intro-agence__infos .infos__top .top__agence .agence__text {
        display: flex;
        margin-top: 5px;
        font-size: 18px;
        line-height: 1.5;
        color: #ffffff;
        text-decoration: none; }
        .intro-agence__infos .infos__top .top__agence .agence__text i {
          margin-right: 5px;
          font-size: 23px; }
  .intro-agence__infos .infos__map iframe {
    width: 100%;
    height: 440px;
    border-radius: 0 0 20px 20px; }

.text-full-center {
  padding: 80px 0;
  text-align: center; }
  @media (max-width: 768px) {
    .text-full-center {
      text-align: left; } }
  @media (max-width: 768px) {
    .text-full-center {
      padding: 50px 0; } }
  .text-full-center h1, .text-full-center h2, .text-full-center h3, .text-full-center h4 {
    margin-bottom: 50px;
    font-size: 66px;
    font-weight: bold; }
    @media (max-width: 1440px) {
      .text-full-center h1, .text-full-center h2, .text-full-center h3, .text-full-center h4 {
        font-size: 64px; } }
    @media (max-width: 1023px) {
      .text-full-center h1, .text-full-center h2, .text-full-center h3, .text-full-center h4 {
        font-size: 60px; } }
    @media (max-width: 768px) {
      .text-full-center h1, .text-full-center h2, .text-full-center h3, .text-full-center h4 {
        font-size: 40px;
        margin-bottom: 30px; } }
    .text-full-center h1:before, .text-full-center h2:before, .text-full-center h3:before, .text-full-center h4:before {
      content: '# '; }
  .text-full-center p {
    max-width: 1030px;
    margin: 30px auto;
    font-size: 18px;
    font-weight: 300; }
    .text-full-center p:last-child {
      margin-bottom: 0; }
    .text-full-center p strong, .text-full-center p b {
      font-weight: 600; }
    .text-full-center p a {
      text-decoration: underline;
      color: inherit; }
      .text-full-center p a:hover {
        text-decoration: none; }

.text-img {
  display: flex; }
  @media (max-width: 768px) {
    .text-img {
      flex-direction: column; } }
  .text-img__text {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    padding: 80px 0;
    box-sizing: border-box;
    padding-right: 80px; }
    @media (max-width: 1023px) {
      .text-img__text {
        padding-right: 30px; } }
    @media (max-width: 768px) {
      .text-img__text {
        width: 100%;
        padding: 50px 15px; } }
    .text-img__text .text__content {
      width: 100%;
      max-width: 560px;
      padding-left: 30px; }
      @media (max-width: 768px) {
        .text-img__text .text__content {
          padding: 0;
          max-width: 100%; } }
      .text-img__text .text__content--white {
        color: #ffffff; }
        .text-img__text .text__content--white a {
          color: #ffffff; }
      .text-img__text .text__content--black {
        color: #000000; }
        .text-img__text .text__content--black a {
          color: #000000; }
      .text-img__text .text__content--grey {
        color: #464646; }
        .text-img__text .text__content--grey a {
          color: #464646; }
      .text-img__text .text__content img {
        margin-bottom: 50px; }
        @media (max-width: 768px) {
          .text-img__text .text__content img {
            margin-bottom: 30px; } }
      .text-img__text .text__content h1, .text-img__text .text__content h2, .text-img__text .text__content h3, .text-img__text .text__content h4 {
        margin-bottom: 50px;
        font-size: 66px;
        font-weight: bold;
        font-family: "Cera pro", sans-serif; }
        @media (max-width: 1440px) {
          .text-img__text .text__content h1, .text-img__text .text__content h2, .text-img__text .text__content h3, .text-img__text .text__content h4 {
            font-size: 64px; } }
        @media (max-width: 1023px) {
          .text-img__text .text__content h1, .text-img__text .text__content h2, .text-img__text .text__content h3, .text-img__text .text__content h4 {
            font-size: 60px; } }
        @media (max-width: 768px) {
          .text-img__text .text__content h1, .text-img__text .text__content h2, .text-img__text .text__content h3, .text-img__text .text__content h4 {
            font-size: 40px;
            margin-bottom: 30px; } }
        .text-img__text .text__content h1:before, .text-img__text .text__content h2:before, .text-img__text .text__content h3:before, .text-img__text .text__content h4:before {
          content: '# '; }
      .text-img__text .text__content p {
        max-width: 1030px;
        margin: 30px auto;
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
        text-align: justify; }
        .text-img__text .text__content p:first-child {
          margin-top: 0; }
        .text-img__text .text__content p:last-child {
          margin-bottom: 0; }
        .text-img__text .text__content p strong, .text-img__text .text__content p b {
          font-weight: 600; }
        .text-img__text .text__content p a {
          text-decoration: underline; }
  .text-img__img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    padding: 80px 0;
    box-sizing: border-box;
    padding-left: 80px; }
    @media (max-width: 1023px) {
      .text-img__img {
        padding-left: 30px; } }
    @media (max-width: 768px) {
      .text-img__img {
        width: 100%;
        padding: 50px 15px; } }
    .text-img__img .img__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 560px;
      padding-right: 30px;
      font-size: 28px;
      text-align: center; }
      @media (max-width: 768px) {
        .text-img__img .img__content {
          padding: 0;
          max-width: 100%; } }
      .text-img__img .img__content .content__text-above {
        margin-bottom: 40px; }
      .text-img__img .img__content .content__text-under {
        margin-top: 40px; }
    .text-img__img--img-full {
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      @media (max-width: 768px) {
        .text-img__img--img-full {
          height: 100vw; } }
  .text-img--reverse {
    flex-direction: row-reverse; }
    @media (max-width: 768px) {
      .text-img--reverse {
        flex-direction: column; } }
    .text-img--reverse .text-img__text {
      justify-content: flex-start;
      padding-right: 30px;
      padding-left: 80px; }
      @media (max-width: 1023px) {
        .text-img--reverse .text-img__text {
          padding-left: 30px; } }
      @media (max-width: 768px) {
        .text-img--reverse .text-img__text {
          padding-right: 0;
          width: 100%;
          padding: 50px 15px; } }
      .text-img--reverse .text-img__text .text__content {
        padding-left: 0;
        padding-right: 30px; }
        @media (max-width: 768px) {
          .text-img--reverse .text-img__text .text__content {
            padding: 0; } }
    .text-img--reverse .text-img__img {
      justify-content: flex-end;
      padding-left: 0;
      padding-right: 80px; }
      @media (max-width: 1023px) {
        .text-img--reverse .text-img__img {
          padding-left: 0;
          padding-right: 30px; } }
      @media (max-width: 768px) {
        .text-img--reverse .text-img__img {
          padding: 50px 15px; } }
      .text-img--reverse .text-img__img .img__content {
        padding-right: 0;
        padding-left: 30px; }
        @media (max-width: 768px) {
          .text-img--reverse .text-img__img .img__content {
            padding: 0; } }

.img-full-width img {
  display: block; }

.img-full-width--agence img {
  max-height: 436px;
  object-fit: cover; }

.block-video {
  padding-top: 80px;
  border-bottom: 95px solid #ffffff; }
  @media (max-width: 1023px) {
    .block-video {
      border-width: 75px; } }
  @media (max-width: 768px) {
    .block-video {
      padding-top: 50px; } }
  .block-video__text {
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 1023px) {
      .block-video__text {
        display: block; } }
    .block-video__text > div {
      width: 420px; }
      @media (max-width: 768px) {
        .block-video__text > div {
          width: 100%; } }
      .block-video__text > div h3 {
        margin-bottom: 30px;
        font-size: 68px;
        font-weight: 700;
        font-family: Cera pro, sans-serif; }
        .block-video__text > div h3:before {
          content: "# "; }
        @media (max-width: 1023px) {
          .block-video__text > div h3 {
            font-size: 60px; } }
        @media (max-width: 768px) {
          .block-video__text > div h3 {
            font-size: 40px; } }
      .block-video__text > div p {
        margin: 30px auto;
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
        text-align: justify; }
  .block-video__video {
    position: relative;
    max-width: 1070px;
    margin: auto;
    margin-bottom: -95px; }
    @media (max-width: 1023px) {
      .block-video__video {
        margin-bottom: -75px; } }
    .block-video__video .video__bullets {
      position: absolute;
      top: -90px;
      left: -125px;
      width: 633px;
      height: 370px;
      background: url(../images/bg-video.png) no-repeat;
      background-size: cover; }
      @media (max-width: 1023px) {
        .block-video__video .video__bullets {
          display: none; } }
    .block-video__video video {
      position: relative;
      display: block;
      width: 100%;
      height: auto; }

.discover-links {
  width: fit-content;
  margin-top: 50px;
  background: #FBF9F6;
  border-radius: 30px;
  padding: 20px 25px; }
  @media (max-width: 768px) {
    .discover-links {
      padding: 15px; } }
  .discover-links__title {
    color: #464646;
    font-size: 18px;
    font-weight: bold; }
  .discover-links__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    .discover-links__content .button {
      margin: 10px 10px 0 10px; }
      @media (max-width: 768px) {
        .discover-links__content .button {
          margin: 10px 5px 0 5px; } }

.addition {
  padding: 80px 0; }
  .addition.no-padding-top {
    padding-top: 0; }
  .addition .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    @media (max-width: 768px) {
      .addition .wrap {
        flex-direction: column; } }
  .addition__item {
    display: flex;
    align-items: center; }
    .addition__item span {
      margin-left: 20px;
      font-size: 24px;
      font-weight: 600; }
  .addition__ope {
    margin: 0 30px;
    font-size: 72px;
    font-weight: 300; }
    @media (max-width: 768px) {
      .addition__ope {
        margin: 0; } }

.title-projet {
  position: relative;
  height: 742px; }
  .title-projet .wrap {
    position: relative; }
  .title-projet__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    max-width: none;
    object-fit: cover; }
  .title-projet__return {
    position: absolute;
    top: 20px;
    left: 30px;
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #000000;
    text-decoration: none;
    transition: color 0.25s;
    z-index: 1; }
    @media (max-width: 768px) {
      .title-projet__return {
        left: 15px; } }
    .title-projet__return i {
      margin-right: 10px;
      transform: rotate(180deg);
      font-size: 32px; }
    .title-projet__return--white {
      color: #ffffff; }
      .title-projet__return--white:hover {
        color: #E5007D; }
    .title-projet__return:hover {
      color: #E5007D; }
  .title-projet__logo {
    position: absolute;
    top: 65px;
    left: 30px; }
    @media (max-width: 768px) {
      .title-projet__logo {
        left: 15px; } }
  .title-projet__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 742px;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center; }
    @media (max-width: 768px) {
      .title-projet__text {
        padding: 0 15px; } }
    .title-projet__text .text__title {
      font-family: "Cera pro", sans-serif;
      font-size: 85px;
      font-weight: 900;
      text-shadow: 0px 5.48718px 2.74359px rgba(0, 0, 0, 0.41);
      text-transform: uppercase; }
      @media (max-width: 768px) {
        .title-projet__text .text__title {
          font-size: 60px; } }
    .title-projet__text .text__accroche {
      font-family: "Open Sans", "Helvetica", "arial", sans-serif;
      font-size: 50px;
      font-weight: 300;
      text-shadow: 0px 2.48718px 1.34359px rgba(0, 0, 0, 0.41); }
      @media (max-width: 768px) {
        .title-projet__text .text__accroche {
          font-size: 35px; } }

.intro-projet .wrap {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .intro-projet .wrap {
      flex-direction: column; } }

.intro-projet__text {
  width: 100%;
  max-width: 640px;
  margin-right: 50px;
  padding: 50px 0;
  color: #000000; }
  @media (max-width: 768px) {
    .intro-projet__text {
      width: 100%; } }
  .intro-projet__text h1,
  .intro-projet__text h2 {
    margin-bottom: 20px;
    font-size: 38px;
    font-weight: bold; }
  .intro-projet__text p {
    margin: 30px 0;
    text-align: justify;
    font-weight: 300;
    font-size: 18px; }
    .intro-projet__text p strong, .intro-projet__text p b {
      font-weight: 600; }
    .intro-projet__text p a {
      font-weight: 400;
      color: #E5007D;
      text-decoration: underline; }
      .intro-projet__text p a:hover {
        text-decoration: none; }

.intro-projet__infos {
  width: 100%;
  max-width: 464px;
  padding: 50px 0;
  color: #000000; }
  @media (max-width: 768px) {
    .intro-projet__infos {
      padding-top: 0;
      max-width: none; } }
  .intro-projet__infos .infos {
    padding: 35px 50px 35px 80px;
    background: #F2F2F2;
    border-radius: 20px; }
    @media (max-width: 1340px) {
      .intro-projet__infos .infos {
        border-radius: 20px 0px 0px 20px;
        transform: translateX(30px); } }
    @media (max-width: 768px) {
      .intro-projet__infos .infos {
        transform: translateX(0);
        border-radius: 20px;
        padding: 20px 20px 20px 50px; } }
    .intro-projet__infos .infos__baseline {
      position: relative;
      margin-top: 20px;
      font-weight: 700;
      font-size: 30px;
      font-style: italic; }
      .intro-projet__infos .infos__baseline::before {
        position: absolute;
        top: -10px;
        left: -25px;
        font-size: 60px;
        font-weight: 400;
        font-style: normal;
        content: "‘‘"; }
      .intro-projet__infos .infos__baseline::after {
        margin-left: 11px;
        display: inline-block;
        content: " ’’";
        font-size: 60px;
        font-weight: 400;
        font-style: normal;
        line-height: 0;
        transform: translateY(20px); }
    .intro-projet__infos .infos__website {
      margin-top: 10px;
      font-size: 24px;
      font-weight: bold; }
    .intro-projet__infos .infos__description {
      position: relative;
      margin-top: 25px;
      max-width: 210px;
      font-weight: 300;
      line-height: 21px; }
      @media (max-width: 768px) {
        .intro-projet__infos .infos__description {
          max-width: none; } }
      .intro-projet__infos .infos__description::before {
        position: absolute;
        top: 5px;
        left: -25px;
        content: "";
        width: 13px;
        height: 13px;
        background: #E5007D; }

.categories-projet {
  padding: 60px 0; }
  .categories-projet .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    @media (max-width: 768px) {
      .categories-projet .wrap {
        flex-direction: column; } }
  .categories-projet__item {
    display: flex;
    align-items: center;
    margin: 10px 40px;
    color: #ffffff;
    text-decoration: none; }
    @media (max-width: 768px) {
      .categories-projet__item {
        margin: 10px 0; } }
    .categories-projet__item span {
      margin-left: 20px;
      font-size: 24px;
      font-weight: 600; }

.title-charte {
  padding: 80px 0;
  background-color: #E5007D;
  color: #ffffff; }
  @media (max-width: 768px) {
    .title-charte {
      padding: 50px 0; } }
  .title-charte .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 60px;
    font-weight: 900;
    font-family: "Cera pro", sans-serif;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .title-charte .wrap {
        flex-direction: column; } }
    .title-charte .wrap img {
      margin-left: 30px; }
      @media (max-width: 768px) {
        .title-charte .wrap img {
          margin-left: 0px;
          margin-top: 20px; } }

.intro-charte .wrap {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .intro-charte .wrap {
      flex-direction: column; } }

.intro-charte__text {
  width: 100%;
  max-width: 640px;
  margin-right: 50px;
  padding: 50px 0;
  color: #000000; }
  @media (max-width: 768px) {
    .intro-charte__text {
      width: 100%; } }
  .intro-charte__text h1,
  .intro-charte__text h2 {
    margin-bottom: 20px;
    font-size: 38px;
    font-weight: bold; }
  .intro-charte__text p {
    margin: 30px 0;
    text-align: justify;
    font-weight: 300;
    font-size: 18px; }
    .intro-charte__text p strong, .intro-charte__text p b {
      font-weight: 600; }

.intro-charte__infos {
  width: 100%;
  max-width: 464px;
  text-align: center;
  padding: 50px 0; }
  @media (max-width: 768px) {
    .intro-charte__infos {
      padding-top: 0;
      max-width: none; } }
  .intro-charte__infos .infos__website {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold; }

.colors-charte {
  display: flex; }
  @media (max-width: 768px) {
    .colors-charte {
      flex-direction: column; } }
  .colors-charte__text {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    padding: 80px 0;
    box-sizing: border-box;
    padding-right: 80px; }
    @media (max-width: 1023px) {
      .colors-charte__text {
        padding-right: 30px; } }
    @media (max-width: 768px) {
      .colors-charte__text {
        width: 100%;
        padding: 50px 15px; } }
    .colors-charte__text .text__content {
      width: 100%;
      max-width: 560px;
      padding-left: 30px;
      color: #000000; }
      @media (max-width: 768px) {
        .colors-charte__text .text__content {
          padding: 0;
          max-width: 100%; } }
      .colors-charte__text .text__content--white {
        color: #ffffff; }
        .colors-charte__text .text__content--white a {
          color: #ffffff; }
      .colors-charte__text .text__content--black {
        color: #000000; }
        .colors-charte__text .text__content--black a {
          color: #000000; }
      .colors-charte__text .text__content--grey {
        color: #464646; }
        .colors-charte__text .text__content--grey a {
          color: #464646; }
      .colors-charte__text .text__content img {
        margin-bottom: 50px; }
        @media (max-width: 768px) {
          .colors-charte__text .text__content img {
            margin-bottom: 30px; } }
      .colors-charte__text .text__content h1, .colors-charte__text .text__content h2 {
        margin-bottom: 50px;
        font-size: 68px;
        font-weight: bold;
        font-family: "Cera pro", sans-serif; }
        @media (max-width: 1023px) {
          .colors-charte__text .text__content h1, .colors-charte__text .text__content h2 {
            font-size: 60px; } }
        @media (max-width: 768px) {
          .colors-charte__text .text__content h1, .colors-charte__text .text__content h2 {
            font-size: 40px;
            margin-bottom: 30px; } }
      .colors-charte__text .text__content p {
        max-width: 1030px;
        margin: 30px auto;
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
        text-align: justify; }
        .colors-charte__text .text__content p:first-child {
          margin-top: 0; }
        .colors-charte__text .text__content p:last-child {
          margin-bottom: 0; }
        .colors-charte__text .text__content p a {
          text-decoration: underline; }
  .colors-charte__colors {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    box-sizing: border-box;
    position: relative; }
    @media (max-width: 768px) {
      .colors-charte__colors {
        width: 100%;
        height: 100vw; } }
    .colors-charte__colors > div {
      position: absolute;
      display: flex;
      align-items: flex-end;
      box-sizing: border-box;
      padding: 20px;
      font-size: 15px;
      font-weight: bold;
      font-family: "Cera pro", sans-serif; }
      @media (max-width: 768px) {
        .colors-charte__colors > div {
          padding: 15px;
          font-size: 13px; } }
    .colors-charte__colors--2 > div {
      width: 50%;
      height: 100%; }
    .colors-charte__colors--2 > div:nth-child(1) {
      top: 0;
      left: 0; }
    .colors-charte__colors--2 > div:nth-child(2) {
      top: 0;
      left: 50%; }
    .colors-charte__colors--3 > div {
      width: calc(100% / 3);
      height: 100%; }
    .colors-charte__colors--3 > div:nth-child(1) {
      top: 0;
      left: 0; }
    .colors-charte__colors--3 > div:nth-child(2) {
      top: 0;
      left: calc(100% / 3); }
    .colors-charte__colors--3 > div:nth-child(3) {
      top: 0;
      left: calc((100% / 3)*2); }
    .colors-charte__colors--4 > div {
      width: 50%;
      height: 50%; }
    .colors-charte__colors--4 > div:nth-child(1) {
      top: 0;
      left: 0; }
    .colors-charte__colors--4 > div:nth-child(2) {
      top: 0;
      left: 50%; }
    .colors-charte__colors--4 > div:nth-child(3) {
      top: 50%;
      left: 0%; }
    .colors-charte__colors--4 > div:nth-child(4) {
      top: 50%;
      left: 50%; }
    .colors-charte__colors--5 > div {
      height: 50%; }
    .colors-charte__colors--5 > div:nth-child(1) {
      width: 50%;
      top: 0;
      left: 0; }
    .colors-charte__colors--5 > div:nth-child(2) {
      width: 50%;
      top: 0;
      left: 50%; }
    .colors-charte__colors--5 > div:nth-child(3) {
      width: calc(100% / 3);
      top: 50%;
      left: 0; }
    .colors-charte__colors--5 > div:nth-child(4) {
      width: calc(100% / 3);
      top: 50%;
      left: calc(100% / 3); }
    .colors-charte__colors--5 > div:nth-child(5) {
      width: calc(100% / 3);
      top: 50%;
      left: calc((100% / 3)*2); }
    .colors-charte__colors--6 > div {
      width: calc(100% / 3);
      height: 50%; }
    .colors-charte__colors--6 > div:nth-child(1) {
      top: 0;
      left: 0; }
    .colors-charte__colors--6 > div:nth-child(2) {
      top: 0;
      left: calc(100% / 3); }
    .colors-charte__colors--6 > div:nth-child(3) {
      top: 0;
      left: calc((100% / 3)*2); }
    .colors-charte__colors--6 > div:nth-child(4) {
      top: 50%;
      left: 0; }
    .colors-charte__colors--6 > div:nth-child(5) {
      top: 50%;
      left: calc(100% / 3); }
    .colors-charte__colors--6 > div:nth-child(6) {
      top: 50%;
      left: calc((100% / 3)*2); }
  .colors-charte--reverse {
    flex-direction: row-reverse; }
    @media (max-width: 768px) {
      .colors-charte--reverse {
        flex-direction: column; } }
    .colors-charte--reverse .colors-charte__text {
      justify-content: flex-start;
      padding-right: 30px;
      padding-left: 80px; }
      @media (max-width: 1023px) {
        .colors-charte--reverse .colors-charte__text {
          padding-left: 30px; } }
      @media (max-width: 768px) {
        .colors-charte--reverse .colors-charte__text {
          padding-right: 0;
          width: 100%;
          padding: 50px 15px; } }
      .colors-charte--reverse .colors-charte__text .text__content {
        padding-left: 0;
        padding-right: 30px; }
        @media (max-width: 768px) {
          .colors-charte--reverse .colors-charte__text .text__content {
            padding: 0; } }

.custom-message {
  display: none;
  padding: 10px 0; }
  .custom-message .wrap {
    position: relative;
    text-align: center; }
    @media (max-width: 1023px) {
      .custom-message .wrap {
        font-size: 13px; } }
  .custom-message__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }

.realisez p {
  font-size: 25px;
  margin: 0 0 25px 0; }
  @media (max-width: 768px) {
    .realisez p {
      font-size: 21px; } }

.realisez__arrow {
  margin-bottom: 20px;
  transform: rotate(90deg); }
  .realisez__arrow i {
    font-size: 64px;
    color: #E5007D; }

.avis-flottants {
  position: fixed;
  right: 0;
  top: calc(50% - 85px);
  width: 41px;
  height: 171px;
  background: url(../images/avis-clients@2x.jpg) no-repeat;
  background-size: cover;
  border: 1px solid #c9c9c9; }
  @media (max-width: 1023px) {
    .avis-flottants {
      display: none; } }

.avis-verifies {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 540px) {
    .avis-verifies {
      flex-direction: column;
      align-items: center; } }
  .avis-verifies__intro {
    position: relative;
    width: 170px;
    padding: 15px 0;
    background: #ffffff;
    text-align: center;
    text-decoration: none; }
    .avis-verifies__intro .intro__note {
      width: 100%;
      text-align: center;
      font-weight: bold;
      color: #808080;
      font-size: 19px; }
    .avis-verifies__intro .intro__text {
      width: 100%;
      text-align: center;
      color: #808080;
      font-size: 19px; }
  .avis-verifies__slider {
    position: relative;
    width: calc(100% - 170px);
    padding: 0 40px;
    box-sizing: border-box; }
    @media (max-width: 540px) {
      .avis-verifies__slider {
        width: 100%;
        padding: 0 20px;
        margin-top: 20px; } }
    .avis-verifies__slider .slider__prev {
      position: absolute;
      top: calc(50% - 17px);
      left: 10px;
      width: 23px;
      height: 35px;
      background: url(../images/avis-prev.png) no-repeat;
      background-size: cover;
      cursor: pointer; }
      @media (max-width: 540px) {
        .avis-verifies__slider .slider__prev {
          left: -6px; } }
    .avis-verifies__slider .slider__next {
      position: absolute;
      top: calc(50% - 17px);
      right: 10px;
      width: 23px;
      height: 35px;
      background: url(../images/avis-next.png) no-repeat;
      background-size: cover;
      cursor: pointer; }
      @media (max-width: 540px) {
        .avis-verifies__slider .slider__next {
          right: -6px; } }
    .avis-verifies__slider .slider__item {
      position: relative;
      height: 145px;
      margin: 0 3px;
      background: #ffffff;
      overflow: hidden;
      padding: 5px 15px;
      box-shadow: 0 4px 4px -5px #c8c8c8;
      border: 1px solid #ccc;
      box-sizing: border-box;
      border-radius: 5px;
      color: #818181; }
      .avis-verifies__slider .slider__item .item__top {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
        font-size: 11px; }
        .avis-verifies__slider .slider__item .item__top div {
          display: flex; }
      .avis-verifies__slider .slider__item .item__text {
        margin-top: 10px;
        font-size: 13px; }
      .avis-verifies__slider .slider__item .item__author {
        position: absolute;
        bottom: 10px;
        font-size: 11px; }
      .avis-verifies__slider .slider__item .item__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  background: #ffffff;
  transition: background-color 0.25s; }
  .header.scroll {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2); }
  .header.menu-on {
    width: 100vw; }
  .header > .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 110px; }
    @media (max-width: 768px) {
      .header > .wrap {
        height: 60px; } }
  .header .logo {
    position: relative; }
    .header .logo img {
      display: block; }
      @media (max-width: 768px) {
        .header .logo img {
          width: 200px; } }
    .header .logo .logo__img--white {
      position: absolute;
      top: 0;
      display: none; }
  .header__links {
    display: flex;
    align-items: center;
    font-size: 21px;
    font-weight: 900; }
    @media (max-width: 768px) {
      .header__links .links__contact {
        display: none; } }
    .header__links a {
      color: #464646;
      text-decoration: none;
      font-family: "Cera pro", sans-serif;
      font-size: 21px;
      transition: color 0.25s; }
      .header__links a:hover {
        color: #E5007D; }
    .header__links .btn-menu {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: color 0.25s;
      margin-left: 50px; }
      .header__links .btn-menu:hover {
        color: #E5007D; }
      .header__links .btn-menu__text {
        margin-right: 20px;
        font-family: "Cera pro", sans-serif;
        font-size: 21px; }
        @media (max-width: 1023px) {
          .header__links .btn-menu__text {
            display: none; } }
      .header__links .btn-menu__icon i {
        font-size: 48px; }
      .header__links .btn-menu .icon__menu {
        transition: opacity 0.25s; }
      .header__links .btn-menu .icon__close {
        position: absolute;
        right: 0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.25s; }
  .header.menu-on {
    background: #E5007D; }
    .header.menu-on .header__links .links__contact {
      color: #E5007D; }
    .header.menu-on .header__links a {
      color: #ffffff; }
    .header.menu-on .header__links .btn-menu {
      color: #ffffff; }
      .header.menu-on .header__links .btn-menu:hover {
        color: #FCED4E; }
      .header.menu-on .header__links .btn-menu .icon__menu {
        visibility: hidden;
        opacity: 0; }
      .header.menu-on .header__links .btn-menu .icon__close {
        visibility: visible;
        opacity: 1; }
  .header .main-menu {
    display: none;
    position: fixed;
    top: 110px;
    left: 0;
    width: 100vw;
    background: #E5007D; }
    @media (max-width: 768px) {
      .header .main-menu {
        top: 60px; } }
    .header .main-menu .wrap {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: calc(100vh - 110px); }
      @media (max-width: 768px) {
        .header .main-menu .wrap {
          height: calc(100vh - 60px); } }
      .header .main-menu .wrap .content__nav {
        width: 50%;
        margin-top: 5vh; }
        @media (max-width: 1300px) {
          .header .main-menu .wrap .content__nav {
            margin-top: 2vh; } }
        @media (max-width: 1023px) {
          .header .main-menu .wrap .content__nav {
            width: 100%;
            margin-top: 5vh; } }
        .header .main-menu .wrap .content__nav .menu {
          list-style: none;
          padding: 0;
          margin: 0; }
          .header .main-menu .wrap .content__nav .menu li {
            font-family: "Cera pro", sans-serif;
            font-size: 90px;
            font-weight: 900;
            text-align: right;
            text-transform: uppercase;
            margin-top: -15px; }
            @media (max-width: 1300px) {
              .header .main-menu .wrap .content__nav .menu li {
                font-size: 70px;
                margin-top: -20px; } }
            @media (max-width: 1023px) {
              .header .main-menu .wrap .content__nav .menu li {
                font-size: 50px;
                margin-top: -10px; } }
            .header .main-menu .wrap .content__nav .menu li a {
              color: rgba(255, 255, 255, 0.4);
              text-decoration: none;
              transition: color 0.25s; }
              .header .main-menu .wrap .content__nav .menu li a:hover {
                color: #FCED4E; }
            .header .main-menu .wrap .content__nav .menu li.current_page_item a {
              color: #FCED4E; }
        .header .main-menu .wrap .content__nav .nav__secondary {
          position: absolute;
          bottom: 50px;
          right: 30px;
          display: flex; }
          @media (max-width: 768px) {
            .header .main-menu .wrap .content__nav .nav__secondary {
              bottom: 100px; } }
          .header .main-menu .wrap .content__nav .nav__secondary a {
            display: flex;
            align-items: center;
            font-family: "Cera pro", sans-serif;
            font-size: 20px;
            font-weight: 900;
            text-decoration: none;
            color: #ffffff;
            transition: color 0.25s; }
            @media (max-width: 768px) {
              .header .main-menu .wrap .content__nav .nav__secondary a {
                font-size: 14px; } }
            .header .main-menu .wrap .content__nav .nav__secondary a:hover {
              color: #FCED4E; }
            .header .main-menu .wrap .content__nav .nav__secondary a.secondary__engagements {
              text-align: right; }
              .header .main-menu .wrap .content__nav .nav__secondary a.secondary__engagements i {
                font-size: 46px; }
            .header .main-menu .wrap .content__nav .nav__secondary a.secondary__linkedin {
              margin-left: 5px;
              padding-left: 20px;
              border-left: 1px solid #ffffff;
              text-align: right; }
              .header .main-menu .wrap .content__nav .nav__secondary a.secondary__linkedin:hover .linkedin {
                background: #FCED4E; }
              .header .main-menu .wrap .content__nav .nav__secondary a.secondary__linkedin .linkedin {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 44px;
                height: 44px;
                margin-left: 12px;
                background: #ffffff;
                border-radius: 10px;
                transition: all 0.25s; }
                .header .main-menu .wrap .content__nav .nav__secondary a.secondary__linkedin .linkedin i {
                  color: #E5007D;
                  font-size: 32px; }
      .header .main-menu .wrap .content__img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%; }
        @media (max-width: 1023px) {
          .header .main-menu .wrap .content__img {
            display: none; } }
        .header .main-menu .wrap .content__img > div {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%; }
          .header .main-menu .wrap .content__img > div .highlight {
            font-size: 144px;
            text-shadow: 0px 5.90871px 26.5892px rgba(0, 0, 0, 0.68);
            transform: translateY(-50px); }
            .header .main-menu .wrap .content__img > div .highlight:after {
              top: 79%; }

@media (max-width: 768px) {
  .footer {
    padding-bottom: 80px; } }

.footer__eco {
  padding: 10px 0;
  width: 100%;
  background-color: #E5007D;
  color: #ffffff; }
  .footer__eco img {
    margin-right: 10px; }
  .footer__eco .wrap {
    display: flex;
    justify-content: center;
    align-items: center; }
  .footer__eco a {
    color: #ffffff; }

.footer__pre-footer {
  padding: 80px 0;
  background: #FBF9F6; }
  @media (max-width: 768px) {
    .footer__pre-footer .wrap {
      height: 300px; } }
  .footer__pre-footer .wrap iframe {
    width: 100%;
    height: 100%;
    border: 0; }
  .footer__pre-footer .wrap .netreviews-widget {
    overflow-y: hidden; }

.footer__custom-zone {
  padding: 40px 0;
  font-size: 22px; }
  @media (max-width: 768px) {
    .footer__custom-zone {
      font-size: 20px; } }
  .footer__custom-zone .wrap {
    display: flex;
    justify-content: center;
    align-items: center; }
  .footer__custom-zone .custom-zone__img {
    margin-right: 20px; }

.footer__main {
  padding: 85px 0;
  background: #E5007D; }
  .footer__main .main__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 1200px) {
      .footer__main .main__content {
        display: block;
        width: 100%; } }
    .footer__main .main__content .content__left {
      width: calc(70% - 50px); }
      @media (max-width: 1200px) {
        .footer__main .main__content .content__left {
          width: 100%; } }
      .footer__main .main__content .content__left .left__contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: auto;
        margin-top: 30px; }
        @media (max-width: 768px) {
          .footer__main .main__content .content__left .left__contact {
            margin-top: 0; } }
        .footer__main .main__content .content__left .left__contact .footer-contact {
          width: calc(33.33333% - 30px); }
          @media (max-width: 1023px) {
            .footer__main .main__content .content__left .left__contact .footer-contact {
              width: calc(50% - 30px);
              margin-top: 40px; } }
          @media (max-width: 768px) {
            .footer__main .main__content .content__left .left__contact .footer-contact {
              width: 100%; } }
    .footer__main .main__content .content__right {
      width: 30%; }
      @media (max-width: 1200px) {
        .footer__main .main__content .content__right {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: auto;
          width: 100%;
          margin-top: 60px; } }
      @media (max-width: 1200px) {
        .footer__main .main__content .content__right .footer-last-news {
          width: calc(33.33333% - 30px); } }
      @media (max-width: 1023px) {
        .footer__main .main__content .content__right .footer-last-news {
          width: calc(50% - 30px);
          margin-top: 40px; } }
      @media (max-width: 768px) {
        .footer__main .main__content .content__right .footer-last-news {
          width: 100%;
          margin-top: 0; } }

.footer__main2 {
  padding: 85px 0;
  background: #E5007D; }
  .footer__main2 .main__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .footer__main2 .main__content .content__left {
      width: 30%; }
      @media (max-width: 1023px) {
        .footer__main2 .main__content .content__left {
          width: 100%;
          margin-top: 40px; } }
    .footer__main2 .main__content .content__right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(70% - 50px); }
      @media (max-width: 1023px) {
        .footer__main2 .main__content .content__right {
          width: 100%;
          margin-top: 60px; } }
      .footer__main2 .main__content .content__right .footer-last-news {
        width: calc(50% - 20px); }
        @media (max-width: 1200px) {
          .footer__main2 .main__content .content__right .footer-last-news {
            width: calc(50% - 30px); } }
        @media (max-width: 768px) {
          .footer__main2 .main__content .content__right .footer-last-news {
            width: 100%;
            margin-top: 0; } }
        .footer__main2 .main__content .content__right .footer-last-news:last-child {
          margin-bottom: 40px; }

.footer__bottom {
  padding: 30px 0 50px 0;
  background: #E5007D;
  color: #ffffff;
  font-size: 15px; }
  .footer__bottom a {
    color: #ffffff;
    text-decoration: underline; }
  .footer__bottom .bottom__credits {
    margin-top: 50px;
    text-align: center; }
    .footer__bottom .bottom__credits a {
      color: #ffffff;
      text-decoration: none; }
      .footer__bottom .bottom__credits a:hover {
        text-decoration: underline; }

.footer .footer__accordion {
  margin-bottom: 50px; }
  .footer .footer__accordion .accordion__item {
    border-color: #464646; }
  .footer .footer__accordion .accordion__item .item__title:hover {
    color: #E5007D; }

.footer-contact {
  display: flex;
  flex-direction: column; }
  .footer-contact i {
    color: #ffffff; }
  .footer-contact .footer-contact__title {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none; }
    .footer-contact .footer-contact__title i {
      margin-left: 7px;
      font-size: 30px;
      transition: all 0.25s; }
    .footer-contact .footer-contact__title:hover i {
      transform: translateX(10px); }
  .footer-contact .footer-contact__subtitle {
    margin-bottom: 7px;
    font-size: 17px;
    font-weight: 600;
    color: #ffffff; }
    .footer-contact .footer-contact__subtitle a {
      text-decoration: none;
      color: #ffffff; }
  .footer-contact .footer-contact__text {
    display: flex;
    margin-top: 5px;
    line-height: 1.5;
    color: #ffffff; }
    .footer-contact .footer-contact__text--phone {
      margin-bottom: 15px; }
    .footer-contact .footer-contact__text i {
      margin-right: 5px;
      font-size: 23px; }
    .footer-contact .footer-contact__text a {
      color: #ffffff;
      text-decoration: none; }
  .footer-contact .footer-contact__button {
    margin-top: auto; }

.footer-last-news {
  display: flex;
  align-items: center;
  margin-bottom: 40px; }
  @media (max-width: 1200px) {
    .footer-last-news {
      width: calc(50% - 50px); } }
  @media (max-width: 1023px) {
    .footer-last-news {
      width: 100%; } }
  .footer-last-news:last-child {
    margin-bottom: 0; }
    @media (max-width: 1200px) {
      .footer-last-news:last-child {
        margin-bottom: 40px; } }
  .footer-last-news .item__img {
    display: block;
    width: 150px;
    height: 85px;
    border-radius: 10px;
    object-fit: cover; }
    @media (max-width: 768px) {
      .footer-last-news .item__img {
        width: 85px;
        height: 85px !important; } }
  .footer-last-news .item__text {
    margin-left: 15px; }
    .footer-last-news .item__text .text__title {
      font-size: 16px;
      font-weight: 900;
      color: #ffffff; }
    .footer-last-news .item__text .text__date {
      font-size: 16px;
      color: #ffffff; }
    .footer-last-news .item__text .text__link {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      text-decoration: none; }
      .footer-last-news .item__text .text__link i {
        margin-left: 5px;
        font-size: 28px;
        transition: all 0.25s; }
      .footer-last-news .item__text .text__link:hover i {
        transform: translateX(10px); }

.footer__seo-content {
  padding: 60px 0;
  background: #EAEAEA; }
  .footer__seo-content .seo-content__item {
    margin-top: 50px; }
    .footer__seo-content .seo-content__item:first-child {
      margin: 0; }
      .footer__seo-content .seo-content__item:first-child .item__title {
        margin-bottom: 35px;
        font-size: 25px;
        font-weight: bold;
        text-align: center; }
    .footer__seo-content .seo-content__item .item__title {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bold; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  outline: none;
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@media (max-width: 768px) {
  #netreviewsWidgetNum23197widget10av {
    display: none; } }

.homepage .header {
  background: #E5007D; }
  .homepage .header .logo {
    position: relative; }
    .homepage .header .logo img {
      display: block; }
    .homepage .header .logo .logo__img--white {
      position: absolute;
      top: 0;
      display: block; }
  .homepage .header .header__links .links__contact {
    display: none; }
  .homepage .header .header__links a {
    color: #ffffff; }
    .homepage .header .header__links a:hover {
      color: #FCED4E; }
  .homepage .header .header__links .btn-menu {
    color: #ffffff; }
    .homepage .header .header__links .btn-menu:hover {
      color: #FCED4E; }
  .homepage .header.scroll {
    background: #ffffff; }
    .homepage .header.scroll .logo .logo__img--white {
      display: none; }
    .homepage .header.scroll .header__links a {
      color: #464646; }
    .homepage .header.scroll .header__links .btn-menu {
      color: #464646; }
      .homepage .header.scroll .header__links .btn-menu:hover {
        color: #E5007D; }
    .homepage .header.scroll.menu-on {
      background: #E5007D; }
      .homepage .header.scroll.menu-on .header__links a {
        color: #ffffff; }
      .homepage .header.scroll.menu-on .header__links .btn-menu {
        color: #ffffff; }
        .homepage .header.scroll.menu-on .header__links .btn-menu:hover {
          color: #FCED4E; }
        .homepage .header.scroll.menu-on .header__links .btn-menu .icon__menu {
          visibility: hidden;
          opacity: 0; }
        .homepage .header.scroll.menu-on .header__links .btn-menu .icon__close {
          visibility: visible;
          opacity: 1; }

.content-404 {
  max-width: 800px;
  margin: auto;
  margin-bottom: 120px; }
  .content-404 h1 {
    margin-top: 40px;
    font-family: "Cera pro", sans-serif;
    color: #E5007D;
    font-size: 50px;
    font-weight: bold; }
    @media (max-width: 768px) {
      .content-404 h1 {
        font-size: 35px; } }
  .content-404 p {
    margin: 30px 0;
    font-family: "Cera pro", sans-serif;
    font-size: 25px;
    font-weight: 300; }
    @media (max-width: 768px) {
      .content-404 p {
        font-size: 20px; } }
