.footer{
    
    @media (max-width: $mob) {
        padding-bottom:80px;
    }
    &__eco {
        padding: 10px 0;
        width: 100%;
        background-color: $primary;
        color: $white;
        img {
            margin-right: 10px;
        }

        .wrap {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        a {
            color: $white;
        }
    }
    
    &__pre-footer {
        padding: $space 0;
        background: $grey-light;
        .wrap{
            @media (max-width: $mob) {
                height:300px;
            }
            iframe{
                width: 100%;
                height: 100%;
                border: 0;
            }
            .netreviews-widget{
                overflow-y: hidden;
            }
        }
    }
    &__custom-zone{
        padding:40px 0;
        font-size: 22px;
        @media (max-width: $mob) {
            font-size: 20px;
        }
        .wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
        .custom-zone__img{
            margin-right:20px;
        }
    }
    &__main{
        padding: 85px 0;
        background: $primary;
        .main__content{
            display:flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media (max-width: $desksm) {
                display:block;
                width: 100%;
            }
            .content__left{
                width: calc(70% - 50px);
                @media (max-width: $desksm) {
                    width: 100%;
                }
                .left__contact{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top:auto;
                    margin-top:30px;
                    @media (max-width: $mob) {
                        margin-top: 0;
                    }
                    .footer-contact{
                        width:calc(33.33333% - 30px);
                        @media (max-width: $tab) {
                            width: calc(50% - 30px);
                            margin-top:40px;
                        }
                        @media (max-width: $mob) {
                            width: 100%;
                        }
                    }
                }
            }
            .content__right{
                width: 30%;
                @media (max-width: $desksm) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: auto;
                    width: 100%;
                    margin-top:60px;
                }
                .footer-last-news {
                    @media (max-width: $desksm) {
                        width: calc(33.33333% - 30px);
                    }
                    @media (max-width: $tab) {
                        width: calc(50% - 30px);
                        margin-top: 40px;
                    }
                    @media (max-width: $mob) {
                        width: 100%;
                        margin-top:0;
                    }
                }
            }
        }
    }
    &__main2{
        padding: 85px 0;
        background: $primary;
        .main__content{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .content__left{
                width: 30%;
                @media (max-width: $tab) {
                    width: 100%;
                    margin-top:40px;
                }
            }
            .content__right {
                display: flex;
                flex-wrap:wrap;
                justify-content: space-between;
                width: calc(70% - 50px);
                @media (max-width: $tab) {
                    width: 100%;
                    margin-top:60px;
                }
                .footer-last-news {
                    width: calc(50% - 20px);
                    @media (max-width: $desksm) {
                        width: calc(50% - 30px);
                    }
                    @media (max-width: $mob) {
                        width: 100%;
                        margin-top: 0;
                    }
                    &:last-child{
                        margin-bottom:40px;
                    }
                }
            }
        }
    }
    &__bottom{
        padding: 30px 0 50px 0;
        background: $primary;
        color: $white;
        font-size: 15px;
        a{
            color:$white;
            text-decoration:underline;
        }
        .bottom{
            &__credits{
                margin-top:50px;
                text-align:center;
                a{
                    color:$white;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    } 
                }
            }
        }
    }  
    .footer__accordion{
        margin-bottom:50px;
        .accordion__item{
            border-color: $grey-dark;
        }
        .accordion__item .item__title:hover{
            color:$primary;
        }
    }
}



.footer-contact {
    display: flex;
    flex-direction: column;
    i {
        color: $white;
    }

    .footer-contact__title {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;

        i {
            margin-left: 7px;
            font-size: 30px;
            transition: all 0.25s;
        }

        &:hover {
            i {
                transform: translateX(10px);
            }
        }
    }

    .footer-contact__subtitle {
        margin-bottom: 7px;
        font-size: 17px;
        font-weight: 600;
        color: $white;
        a {
            text-decoration: none;
            color: $white;
        }
    }

    .footer-contact__text {
        display: flex;
        margin-top: 5px;
        line-height: 1.5;
        color: $white;
        &--phone{
            margin-bottom: 15px;
        }
        i {
            margin-right: 5px;
            font-size: 23px;
        }

        a {
            color: $white;
            text-decoration: none;
        }
    }

    .footer-contact__button {
        margin-top: auto;
    }
}


.footer-last-news {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    @media (max-width: $desksm) {
        width: calc(50% - 50px);
    }

    @media (max-width: $tab) {
        width: 100%;
    }

    &:last-child {
        margin-bottom: 0;

        @media (max-width: $desksm) {
            margin-bottom: 40px;
        }
    }

    .item {
        &__img {
            display: block;
            width: 150px;
            height: 85px;
            border-radius: 10px;
            object-fit: cover;

            @media (max-width: $mob) {
                width: 85px;
                height: 85px !important;
            }
        }

        &__text {
            margin-left: 15px;

            .text {
                &__title {
                    font-size: 16px;
                    font-weight: 900;
                    color: $white;
                }

                &__date {
                    font-size: 16px;
                    color: $white;
                }

                &__link {
                    display: inline-flex;
                    align-items: center;
                    font-size: 16px;
                    color: $white;
                    text-decoration: none;

                    i {
                        margin-left: 5px;
                        font-size: 28px;
                        transition: all 0.25s;
                    }

                    &:hover {
                        i {
                            transform: translateX(10px);
                        }
                    }
                }
            }
        }
    }
}

.footer__seo-content{
    padding:60px 0;
    background:#EAEAEA;
    .seo-content__item{
        margin-top:50px;
        &:first-child{
            margin:0;
            .item__title {
                margin-bottom:35px;
                font-size: 25px;
                font-weight: bold;
                text-align:center;
            }
        }
        .item__title{
            margin-bottom: 20px;
            font-size:20px;
            font-weight:bold;
        }
    }
}