.img-full-width{
    img {
        display:block;
    }
    &--agence{
        img{
            max-height: 436px; 
            object-fit: cover;

        }
    }
}