.intro-text-img{
    .wrap{
        display:flex;
        @media (max-width: $mob) {
            flex-direction: column-reverse
        }
    }
    &__text{
        width:50%;
        padding:$space-mobile 0;
        color:$black;
        @media (max-width: $mob) {
            width:100%;
        }
        h1, h2{
            margin-bottom:40px;
            font-size:38px;
            font-weight:bold;
        }
        p{
            margin:30px 0;
            text-align: justify;
            font-weight:300;
            font-size:18px;
            strong, b{
                font-weight:600;
            }
            a {
                font-weight:400;
                color: $primary;
                text-decoration: underline;
            
                &:hover {
                    text-decoration: none;
                }
            }
        }
        
    }
    &__img{
        display:flex;
        justify-content: center;
        align-items: center;
        width:50%;
        box-sizing: border-box;
        padding-left:50px;
        @media (max-width: $mob) {
            width: 100%;
            padding: $space-mobile 0 0 0;
        }
    }
}