.last-articles{
    padding:0 0 $space 0;
    @media (max-width: $mob) {
        padding: 0 0 $space-mobile 0;
    }
    .wrap{
        max-width:1060px;
    }
    &__header{
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding-top:$space;
        border-top:1px solid $black;
        @media (max-width: $mob) {
            flex-wrap: wrap;
            justify-content: center;
            padding-top: $space-mobile;
        }
        .header{
            &__title{
                font-size:30px;
                font-weight:bold;
            }
            &__link{
                display:inline-flex;
                align-items: center;
                font-weight: 600;
                font-size: 20px;
                color:$primary;
                text-decoration:none;
                span{
                    text-decoration:underline;
                }
                i {
                    margin-left: 5px;
                    font-size: 32px;
                    transition: all 0.25s;
                }
                
                &:hover {
                    i {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
    &__content{
        display:flex;
        justify-content: space-between;
        @media (max-width: $tab) {
            flex-wrap: wrap;
        }
        .content__item{
            width:calc(25% - 20px);
            margin-top:40px;
            @media (max-width: $tab) {
                width:calc(50% - 20px);
            }
            @media (max-width: $mob) {
                width: 100%;
            }
            .item{
                &__img{
                    border-radius:30px;
                    height:147px !important;
                    object-fit: cover;
                    @media (max-width: $tab) {
                        width: 100%;
                        height:200px !important;
                    }
                    @media (max-width: $mob) {
                        height:147px !important;
                    }
                }
                &__title{
                    margin:20px 0 10px 0;
                    font-weight: 700;
                    font-size: 26px;
                }
                &__link {
                    display: inline-flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 16px;
                    color: $primary;
                    text-decoration: none;
                
                    span {
                        text-decoration: underline;
                    }
                
                    i {
                        margin-left: 5px;
                        font-size: 28px;
                        transition: all 0.25s;
                    }
                
                    &:hover {
                        i {
                            transform: translateX(10px);
                        }
                    }
                }
            }
        }
    }
}