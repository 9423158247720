.header{
    position:sticky;
    top:0;
    left:0;
    z-index:$z-header;
    width:100%;
    background:$white;
    transition: background-color 0.25s;
    &.scroll{
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2);
    }
    &.menu-on{
        width: 100vw;
    }
    & > .wrap{
        display:flex;
        align-items: center;
        justify-content: space-between;
        height:110px;
        @media (max-width: $mob) {
            height:60px;
        }
    }
    .logo {
        position:relative;
        img {
            display: block;
            @media (max-width: $mob) {
                width:200px;
            }
        }
        .logo__img--white{
            position:absolute;
            top:0;
            display:none;
        }
    }
    &__links{
        display:flex;
        align-items: center;
        font-size:21px;
        font-weight:900;
        .links__contact{
            @media (max-width: $mob) {
                display:none;
            }
        }
        a{
            color:$grey-dark;
            text-decoration:none;
            font-family: $font-2;
            font-size: 21px;
            transition: color 0.25s;
            &:hover {
                color: $primary;
            }
        }
        .btn-menu{
            position:relative;
            display:flex;
            align-items: center;
            cursor:pointer;
            transition:color 0.25s;
            margin-left:50px;
            &:hover{
                color:$primary;
            }
            &__text{
                margin-right:20px;
                font-family: $font-2;
                font-size:21px;
                @media (max-width: $tab) {
                    display:none;
                }
            }
            &__icon{
                i{
                    font-size:48px;
                }
            }
            .icon__menu {
                transition: opacity 0.25s;
            }
            .icon__close{
                position:absolute;
                right:0;
                top:0;
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.25s;
            }
        }
    }
    &.menu-on {
        background: $primary;
        .header__links {
            .links__contact {
                color:$primary;
            }
            a {
                color: $white;
            }
            .btn-menu {
                color: $white;
                &:hover {
                    color: $secondary;
                }
                .icon__menu {
                    visibility: hidden;
                    opacity: 0;
                }
                .icon__close {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    .main-menu{
        display:none;
        position:fixed;
        top:110px;
        left:0;
        width:100vw;
        background:$primary;
        @media (max-width: $mob) {
            top:60px;
        }
        .wrap {
            position:relative;
            display: flex;
            justify-content: space-between;
            height: calc(100vh - 110px);
            @media (max-width: $mob) {
                height: calc(100vh - 60px);
            }
            .content{
                &__nav{
                    width:50%;
                    margin-top:5vh;
                    @media (max-width: 1300px) {
                        margin-top: 2vh;
                    }
                    @media (max-width: $tab) {
                        width:100%;
                        margin-top: 5vh;
                    }
                    .menu{
                        list-style: none;
                        padding:0;
                        margin:0;
                        li{
                            font-family:$font-2;
                            font-size:90px;
                            font-weight:900;
                            text-align:right;
                            text-transform:uppercase;
                            margin-top: -15px;
                            @media (max-width: 1300px) {
                                font-size: 70px;
                                margin-top: -20px;
                            }
                            @media (max-width: $tab) {
                                font-size:50px;
                                margin-top: -10px;
                            }
                            a{
                                color: rgba($white, 0.4);
                                text-decoration: none;
                                transition: color 0.25s;
                                &:hover{
                                    color:$secondary;
                                }
                            }
                            &.current_page_item{
                                a {
                                    color: $secondary;
                                }
                            }
                        }
                    }
                    .nav__secondary{
                        position:absolute;
                        bottom:50px;
                        right:30px;
                        display:flex;
                        @media (max-width: $mob) {
                            bottom: 100px;
                        } 
                        a{
                            display:flex;
                            align-items: center;
                            font-family:$font-2;
                            font-size:20px;
                            font-weight:900;
                            text-decoration:none;
                            color:$white;
                            transition:color 0.25s;
                            @media (max-width: $mob) {
                                font-size:14px;
                            }
                            &:hover{
                                color:$secondary;
                            }
                            &.secondary{
                                &__engagements{
                                    text-align: right;
                                    i{
                                        font-size:46px;
                                    }
                                }
                                &__linkedin {
                                    margin-left:5px;
                                    padding-left:20px;
                                    border-left:1px solid $white;
                                    text-align: right;
                                    &:hover {
                                        .linkedin {
                                            background: $secondary;
                                        }
                                    }
                                    .linkedin{
                                        display:flex;
                                        align-items: center;
                                        justify-content: center;
                                        width:44px;
                                        height:44px;
                                        margin-left:12px;
                                        background:$white;
                                        border-radius:10px;
                                        transition:all 0.25s;
                                        i {
                                            
                                            color:$primary;
                                            font-size: 32px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &__img {
                    position:relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width:50%;
                    @media (max-width: $tab) {
                       display: none;
                    }
                    & > div{
                        position:absolute;
                        top:0;
                        left:0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width:100%;
                        height:100%;
                        .highlight{
                            font-size:144px;
                            text-shadow: 0px 5.90871px 26.5892px rgba(0, 0, 0, 0.68);
                            transform: translateY(-50px);
                            &:after{
                                top: 79%;
                            }
                        }
                    }
                }
            }
        }
    }
}