.adn{
    padding:$space 0;
    background:$primary;
    color:$white;
    @media (max-width: $mob) {
        padding: $space-mobile 0;
    }
    .wrap {
        @media (max-width: $mob) {
            padding: 0;
        }
    }
    &__slider{
        .slider{
            &__item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: $mob) {
                    flex-direction: column-reverse;
                    padding:0 15px;
                }
                .item{
                    &__text{
                        width:calc(50% - 25px);
                        padding-left:50px;
                        box-sizing: border-box;
                        font-weight: 300;
                        @media (max-width: $mob) {
                            width:100%;
                            padding-left:0;
                        }
                        h2{
                            margin-bottom:50px;
                            font-family: $font-2;
                            font-size:40px;
                            font-weight:bold;
                            @media (max-width: $mob) {
                                font-size:30px;
                                margin-bottom:40px;
                            }
                            .highlight{
                                font-weight:400;
                                font-size:99px;
                                @media (max-width: $mob) {
                                    font-size:69px;
                                    padding-left:20px;
                                }
                            }
                        }
                        p{
                            margin:20px 0;
                            font-size:19px;
                            
                            @media (max-width: $mob) {
                                font-size:17px;
                            }
                            a{
                                color:$white;
                                text-decoration: underline;
                            }
                        }
                        .text__link{
                            display:inline-flex;
                            align-items: center;
                            margin-top:10px;
                            font-size:19px;
                            color:$white;
                            text-decoration:none;
                            transition: color 0.25s;
                            @media (max-width: $mob) {
                                font-size: 17px;
                            }
                            span{
                                text-decoration:underline;
                            }
                            i{
                                margin-left:10px;
                                font-size:36px;
                                transition: transform 0.25s;
                            }
                            &:hover{
                                color:$secondary;
                                i{
                                    transform: translateX(10px);
                                }
                            }
                                
                        }
                    }
                    &__img{
                        display: flex;
                        justify-content: center;
                        width:calc(50% - 25px);
                        @media (max-width: $mob) {
                            width: 100%;
                        }
                        img{
                            @media (max-width: $mob) {
                                max-width:100%;
                                align-self: flex-start;
                            }
                        }
                    }
                }
            }
        }
        .slick-dots{
            display:flex;
            justify-content: center;
            list-style: none;
            li{
                width:8px;
                height:8px;
                margin:0 4px;
                border-radius:12px;
                border:1px solid $white;
                cursor:pointer;
                transition:all 0.25s;
                &:hover, &.slick-active{
                    border-color:$secondary;
                    background:$secondary;
                }
                button {
                    display: none;
                }
            }
            
        }
    }
}