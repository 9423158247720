.carousel{
    width:100%;
    max-width: 560px;
    margin:0 auto 50px auto;
    overflow: hidden;
    filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.12));
    img{
        margin:0 !important;
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        list-style: none;
    
        li {
            width: 8px;
            height: 8px;
            margin: 0 4px;
            border-radius: 12px;
            border: 1px solid $primary;
            cursor: pointer;
            transition: all 0.25s;
    
            &:hover{
                background: $primary;
            }
            &.slick-active {
                background: $secondary;
            }
    
            button {
                display: none;
            }
        }
    
    }
}