.last-projects{
    padding:$space 0;
    @media (max-width: $mob) {
        padding: $space-mobile 0;
    }
    &__content{
        display:flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        @media (max-width: $tab) {
        justify-content: center;
        }
        .content__item{
            position:relative;
            display:flex;
            justify-content: center;
            align-items: center;
            width:calc( (100% / 3) - 20px );
            height: 362px;
            margin:0 10px 20px 10px;
            box-sizing: border-box;
            padding:25px;
            border-radius:30px;
            background-size: cover;
            background-position: center;
            @media (max-width: $tab) {
                width:calc(50% - 20px);
                margin:10px 10px;
            }
            @media (max-width: $mob) {
                width: 100%;
            }
            .item{
                &__logo {
                    transition: opacity 0.25s;
                }
                &__details {
                    position:absolute;
                    top:0;
                    left:0;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    width:100%;
                    height:100%;
                    border-radius:30px;
                    background:rgba(0,0,0,0.6);
                    opacity: 0;
                    transition: opacity 0.25s;
                    @media (max-width: $mob) {
                        display:none;
                    }
                    & > div{
                        padding:20px;
                        color:$white;
                        text-align:center;
                        transform: scale(0);
                        transition: transform 0.25s;
                        .details{
                            &__title{
                                font-family:$font-2;
                                font-size:50px;
                                font-weight:bold;
                                text-transform: uppercase;
                            }
                            &__subtitle{
                                margin-top:5px;
                                font-size:30px;
                                font-weight:300;
                            }
                            &__link{
                                position:relative;
                                z-index:1;
                                display:inline-flex;
                                align-items: center;
                                margin-top:10px;
                                font-size:16px;
                                color:$white;
                                text-decoration:none;
                                span {
                                    text-decoration: underline;
                                }
                                
                                i {
                                    margin-left: 7px;
                                    font-size: 30px;
                                    transition: transform 0.25s;
                                }
                                
                                &:hover {
                                    i {
                                        transform: translateX(5px);
                                    }
                                }
                            }
                            &__tags{
                                margin-top:30px;
                                display:flex;
                                justify-content: center;
                                .tags__item{
                                    margin:0 10px 10px 10px;
                                    font-size:14px;
                                    font-weight:bold;
                                }
                            }
                        }
                    }
                }
                &__link{
                    position:absolute;
                    top:0;
                    left:0;
                    display:block;
                    width:100%;
                    height:100%;
                }
            }
            &:hover{
                .item {
                    &__logo{
                        opacity: 0;
                        @media (max-width: $mob) {
                            opacity: 1;
                        }
                    }
                    &__details {
                        opacity: 1;
                        & > div {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
    &__button{
        margin-top:40px;
        text-align:center;
    }
}