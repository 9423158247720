.filters{
    .wrap{
        display:flex;
        align-items: center;
        justify-content: center;
        margin-top:$space;
        @media (max-width: $tab) {
            display:block;
            margin-top:$space-mobile;
            text-align:center;
        }
    }
    &__list{
        margin: 0 15px;
        @media (max-width: $tab) {
            margin: 15px 0;
        }
    }
    &__see-all{
        text-decoration:underline;
        transition: color 0.25s;
        text-decoration: underline;
        cursor:pointer;
        &:hover{
            color:$primary;
        }
    }
    .filter-button {
        margin: 5px ;
    }
}

.to-filter{
    &.hide {
        transform: scale(0);
        transition: transform 0.25s;
    }
    
    &.hidden {
        display: none !important;
    }
}