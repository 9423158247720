.noscroll {
  overflow-y: hidden;
  margin-right:17px;
  @media (max-width: $tab) {
    margin-right:0;
  }
}

.center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.justify-center{
  justify-content: center;
}

.hide-mobile{
  @media (max-width: $mob) {
    display:none !important;
  }
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-white {
  color: $white !important;
}

.text-grey {
  color: $grey-dark !important;
}

.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}

.bg-white {
  background-color: $white;
}

.bg-grey {
  background-color: $grey-dark;
}

.bg-grey-light {
  background-color: $grey-light;
}
