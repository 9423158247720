.link-download{
    display:inline-block;
    text-decoration:none;
    position:relative;
    margin-bottom: 10px;
    padding: 10px 0 0 60px;
    i{
        position:absolute;
        top:0;
        left:0;
        font-size:35px;
        color:$primary;
        &.white{
            color: $white;
        }
        &.grey{
            color: $grey-dark;
        }
        &.black {
            color: $black;
        }
        &.yellow {
            color: $secondary;
        }
        &.pink {
            color: $primary;
        }
    }
    span{
        font-size:18px;
        text-decoration: underline;
        color: $primary;
        &.white {
            color: $white;
        }
    
        &.grey {
            color: $grey-dark;
        }
    
        &.black {
            color: $black;
        }
    
        &.yellow {
            color: $secondary;
        }
    
        &.pink {
            color: $primary;
        }
    }
    &:hover{
        span {
            text-decoration: none;
        }
    }
}