.contact-agences{
    padding:$space 0;
    .wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: $tab) {
            justify-content: center;
        }
    }
    &__item{
        width:calc( (100% / 3) -  20px);
        margin-bottom:40px;
        border-radius:10px;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.12);
        @media (max-width: $tab) {
            width:calc(50% - 20px);
            margin:0 10px 20px 10px;
        }
        @media (max-width: $mob) {
            width: 100%;
        }
        .item{
            &__img{
                display:block;
                width: 100%;
                height:270px !important;
                object-fit: cover;
                border-top-right-radius:10px;
                border-top-left-radius:10px;
                @media (max-width: $mob) {
                    height:215px !important;
                }
            }
            &__content{
                
                display:flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                height:calc(100% - 270px);
                box-sizing:border-box;
                padding:30px 80px;
                background:$primary;
                border-bottom-right-radius:10px;
                border-bottom-left-radius:10px;
                @media (max-width: $mob) {
                    height:calc(100% - 215px);
                    padding: 30px 20px;
                }
                .content{
                    &__agence {
                        display: inline-block;
                        margin-bottom: 20px;
                        text-align: left;
                        color:$white;
                        .agence {
                            &__city {
                                margin-bottom: 20px;
                                text-transform: uppercase;
                                font-size: 25px;
                                font-weight: 600;
                                a{
                                    color: $white;
                                    text-decoration:none;
                                }
                            }
                
                            &__text {
                                display: flex;
                                margin-top: 5px;
                                font-size: 18px;
                                line-height: 1.5;
                                color: $white;
                                text-decoration: none;
                                i {
                                    margin-right: 5px;
                                    font-size: 23px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}