.main-title{
    background:$primary;
    color:$white;
    .wrap{
        position:relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 224px;
        padding-top: 55px;
        padding-bottom: 30px;
        box-sizing: border-box;
    }
    &__title{
        padding-top:20px;
        font-size:61px;
        font-weight:900;
        text-transform:uppercase;
        transform:translateY(-15px) ;
        text-align:center;
        @media (max-width: $mob) {
            font-size:32px;
        }
        .highlight{
            font-size:100px;
            font-weight:400;
            text-transform:none;
            @media (max-width: $mob) {
                font-size: 70px;
            }
            &::after{
                bottom:-20px;
                @media (max-width: $mob) {
                    left:calc(50% - 60px);
                    width:120px;
                }
            }
        }
    }
    &__return{
        position:absolute;
        top:20px;
        left:30px;
        display: flex;
        align-items: center;
        font-size:17px;
        color:$white;
        text-decoration: none;
        transition:color 0.25s;
        i{
            margin-right:10px;
            transform:rotate(180deg);
            font-size:32px;
        }
        &:hover{
            color:$secondary;
        }
    }
    &__theme {
        margin-bottom:20px;
        padding: 5px 25px;
        background: $secondary;
        color: $primary;
        font-size: 16px;
        font-weight: 700;
    }
    &__date{
        font-size: 16px;
        font-weight:bold;
        text-transform:uppercase;
    }
}