.blog{
    margin-bottom:90px;
    .wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__item{
        position:relative;
        width:calc(50% - 11px);
        margin-top:90px;
        transition: transform 0.25s;
        @media (max-width: $tab) {
            width: 100%;
            margin-top:50px;
        }
        .item{
            &__top{
                position:relative;
                border-radius:30px;
                &:before{
                    content:"";
                    position: absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0) 35%);
                    border-radius:30px;
                    transition: all 0.25s;
                }
                .top{
                    &__img{
                        display:block;
                        width:100%;
                        height:380px !important;
                        object-fit: cover;
                        border-radius:30px;
                        @media (max-width: $mob) {
                            width:100%;
                            height:230px !important;
                        }
                    }
                    &__theme{
                        position: absolute;
                        top:30px;
                        left:0;
                        padding:5px 25px;
                        background:$secondary;
                        color:$primary;
                        font-size:16px;
                        font-weight:bold;
                    }
                    &__meta{
                        position:absolute;
                        bottom:30px;
                        left:30px;
                        display:flex;
                        flex-wrap:wrap;
                        align-items: center;
                        .meta__item{
                            display:flex;
                            align-items: center;
                            margin-right:30px;
                            color:$white;
                            font-weight:bold;
                            i{
                                margin-right:8px;
                            }
                        }
                    }
                }
                &:hover {
                    &:before {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 5%, rgba(0, 0, 0, 0.25) 100%);
                    }
                }
            }
            &__title{
                margin-top:20px;
                font-size:30px;
                font-weight:bold;
            }
            &__excerpt{
                font-weight:300;
                line-height:25px;
            }
            &__link {
                display: inline-flex;
                align-items: center;
                font-size: 16px;
                color: $primary;
                text-decoration: none;
                span{
                    text-decoration: underline;
                }
                i {
                    margin-left: 5px;
                    font-size: 28px;
                    transition: all 0.25s;
                    
                }
                &:hover {
                    i {
                        transform: translateX(10px);
                    }
                }
            }
            &__link-global{
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
            }
            
        }
        
    }
}