.anchors{
    background:#F8F7F7;
    @media (max-width: $mob) {
        display:none;
    }
    .wrap{
        display:flex;
        align-items:center;
        justify-content: space-between;
        height:100px;
        @media (max-width: $tab) {
            flex-wrap: wrap;
            height:140px;
        }
    }
    &__item{
        display:flex;
        align-items:center;
        padding:0 8px;
        font-size:13px;
        font-weight:600;
        color:$grey-dark;
        text-decoration: none;
        @media (max-width: $tab) {
            width: calc(100% / 3);
            box-sizing: border-box;
        }
        @media (max-width: $mob) {
            font-size:13px;
        }
        &:first-child{
            padding-left: 0;
        }
        &:nth-child(2) {
            @media (max-width: $tab) {
                justify-content: center;
            }
        }
        &:nth-child(3){
            @media (max-width: $tab) {
                padding-right: 0;
                justify-content: flex-end;
            }
        }
        &:nth-child(4) {
            @media (max-width: $tab) {
                padding-left: 0;
            }
        }
        &:nth-child(5) {
            @media (max-width: $tab) {
                justify-content: center;
            }
        }
        &:last-child {
            padding-right: 0;
            @media (max-width: $tab) {
                justify-content: flex-end;
            }
        }
        img{
            display:block;
            margin-right:10px;
            @media (max-width: $mob) {
                max-width:25px
            }
        }
    }
}