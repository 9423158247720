.title{
    &-1{
        font-family: $font-2;
        color:$primary;
        font-size:62px;
        text-transform: uppercase;
    }
    &-2 {
        margin-bottom:40px;
        font-family: $font-2;
        font-weight:900;
        color:$primary;
        font-size: 50px;
        @media (max-width: $mob) {
            font-size: 36px;
        }
        &:after{
            content:'';
            margin-top:10px;
            display: block;
            width:34px;
            border-bottom:3px solid $primary;
        }
        &.center{
            &:after {
                margin:15px auto 0
            }
        }
        &.text-white{
            &:after {
                border-color:$white
            }
        }
    }
}