/*
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
*/

@font-face {
  font-family: 'adrenaline-icons';
  src: 
    url('../fonts/adrenaline-icons.woff2') format('woff2'),
    url('../fonts/adrenaline-icons.woff') format('woff'),
    url('../fonts/adrenaline-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'adrenaline-icons';
    src: url('../font/adrenaline-icons.svg?94139383#adrenaline-icons') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "adrenaline-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-phone:before {
  content: '\e800';
}

/* '' */
.icon-logo-adrenaline:before {
  content: '\e801';
}

/* '' */
.icon-engagements:before {
  content: '\e802';
}

/* '' */
.icon-location:before {
  content: '\f031';
}

/* '' */
.icon-linkedin:before {
  content: '\f0e1';
}

/* '' */
.icon-download-cloud:before {
  content: '\f0ed';
}

/* '' */
.icon-arrow-down:before {
  content: '\f175';
}

/* '' */
.icon-arrow-up:before {
  content: '\f176';
}

/* '' */
.icon-arrow-left:before {
  content: '\f177';
}

/* '' */
.icon-arrow-right:before {
  content: '\f178';
}

/* '' */